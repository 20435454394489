import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router';
import 'chartjs-adapter-moment';
import SmallBox from 'components/SmallBox';
import {toastFail} from 'utils/Toast';
import {BsCheckLg, BsXCircleFill} from 'react-icons/bs'
import alertsIcon from '../../../../utils/icons/alert-icon-monitoring.svg';
import './Monitoring.scss';
import moment from 'moment';
import { StyledSwitch, TitleContainer } from 'views/main/home/Home';
import { Button, Container, FormControlLabel } from '@mui/material';
import { Order } from 'stores/AlertsStore';
import PROPERTIES from 'properties';
import { ScreenContainer } from 'views/main/settings/Settings';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useStoresContext } from 'stores/StoresProvider';
import { Category, LoadingOptions, Severity, useAlertsStoreContext } from 'stores/AlertsProvider';
import FiltersBar, { Filters } from 'components/FiltersBar';
import { AlertKey, AlertsTable } from 'views/main/home/AlertsTable';
import { DEFAULT_ROWS_PER_PAGE, isSeverity } from 'views/main/events/Events';
import { TableTitle } from '../Endpoints';
import { useDeviceDetailStoreContext } from 'stores/DeviceDetailProvider';

const { MONITORING } = PROPERTIES

export default function Monitoring() {
    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE)

    const { id } = useParams()
    const stores = useStoresContext()
    const alertsStore = useAlertsStoreContext()
    const deviceDetailStore = useDeviceDetailStoreContext()

    const { windowsConfigStore: WindowsConfigStore } = stores
    const { data, assignPolicy, assignRansomWareProtection } = deviceDetailStore
    const { selectedEndpointLoadingOptions } = alertsStore
    const { severitiesFilter, categoriesFilter, mitigationFilter, order, sortBy } = selectedEndpointLoadingOptions
    
    const filters: Filters = {
        severity: severitiesFilter,
        category: categoriesFilter,
        mitigation: mitigationFilter
    }

    const communityEditionEnvVar = process.env.REACT_APP_COMMUNITY_EDITION
    const communityEdition = communityEditionEnvVar ? communityEditionEnvVar.toLowerCase() === 'true' : false

    useEffect(() => {
        WindowsConfigStore.loadData()
        deviceDetailStore.loadData(id ?? '')
            .catch(() => toastFail('Failed to load the device'))
        alertsStore.loadAlerts()
    }, [])

    // Call for alerts data from the start whenever the rowsPerPage changes
    useEffect(() => {
        alertsStore.setSelectedEndpointLoadOptions({
            count: rowsPerPage,
            cursor: ''
        })
    }, [rowsPerPage])

    useEffect(() => {
        if (id)
            alertsStore.loadSelectedEndpointAlerts(id)
    }, [selectedEndpointLoadingOptions])

    function handleFilterChange(filters: Filters) {
        const options: Partial<LoadingOptions> = {
            severitiesFilter: filters.severity,
            categoriesFilter: filters.category,
            mitigationFilter: filters.mitigation,
            cursor: ''
        }
        alertsStore.setSelectedEndpointLoadOptions(options)
    }

    function handleDownloadCSV() {
        alertsStore.downloadDeviceAlertsCSV(id ?? '', data.hostname ?? '')
    }
    
    function handlePageChange(newPage: number, oldPage: number) {
        if ((newPage - oldPage) === 1)
            alertsStore.setSelectedEndpointLoadOptions({ cursor: alertsStore.selectedEndpointCursorAfter })
        else if ((newPage - oldPage) > 1)
            alertsStore.setSelectedEndpointLoadOptions({ cursor: alertsStore.selectedEndpointCursorLast })
        else if (newPage === 0)
            alertsStore.setSelectedEndpointLoadOptions({ cursor: '' })
        else if ((newPage - oldPage) === -1)
            alertsStore.setSelectedEndpointLoadOptions({ cursor: alertsStore.selectedEndpointCursorBefore })
    }

    function handleSort(order: Order, orderBy: string) {
        alertsStore.setSelectedEndpointLoadOptions({
            order,
            sortBy: orderBy as AlertKey
        })
    }

    function handleClickOnTag(tag: Severity | Category) {
            if (isSeverity(tag))
                alertsStore.setSelectedEndpointLoadOptions({ severitiesFilter: [tag as Severity] })
            else
                alertsStore.setSelectedEndpointLoadOptions({ categoriesFilter: [tag as Category] })
        }

    return (
        <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}} className="Endpoints">
            <TitleContainer>
                <div>
                    <h1>Endpoint: {data.ip_address} ({data.hostname}) </h1>
                    <span className="ostype">OS Type: <span>{data.type === 'desktop' ? 'Windows' : 'Linux'}</span></span>
                    <span className="vertical-line">|</span>
                    <span className="ostype">Account: <span>{data.account_name}</span></span>
                    <span className="vertical-line">|</span>
                    <span className="heartbeat">Last Heartbeat: <span>{moment.utc(data.last_heartbeat).local().format('MMMM Do, YYYY, h:mm A')}</span></span>
                    <span className="vertical-line">|</span>
                    <span className="heartbeat">Agent Version: <span>{data.agent_version}</span></span>
                    <span className="vertical-line">|</span>
                    <span className="protection">Online Status: <span style={{marginLeft: 10}}>{(data.entropy_score ?? 0) >= 2 ? <BsCheckLg color="green"/> : <BsXCircleFill color="red"/>} </span></span>
                </div>
            </TitleContainer>
            <Container style={{marginLeft: 0}} disableGutters>
                { data.type === "desktop" &&
                    <p className="policy-assigned">
                        Memory Protection Inclusion List: |
                        <select
                            defaultValue={"Select a configuration"}
                            value={data.windows_device_configuration_id}
                            onChange={(e) => assignPolicy(e.target.value)}
                        >
                            { data.windows_device_configuration_id != null ?
                                <option disabled> -- select an option -- </option> :
                                <option> -- select an option -- </option>}
                            {
                                WindowsConfigStore.data.map(
                                    (WindowsConfig: any) => <option key={WindowsConfig.id}
                                                                    value={WindowsConfig.id}>{WindowsConfig.name}</option>
                                )
                            }

                        </select>
                    </p>
                }

                <FormControlLabel
                    control={
                        <StyledSwitch
                            checked={data.ransomware_protection_restore_from_backup}
                            onChange={(event, checked) => assignRansomWareProtection(checked)}
                            disabled={communityEdition}/>
                    }
                    label={MONITORING.RANSOMWARE_BACKUP}
                    labelPlacement='start'
                    style={{marginLeft: 0}}
                />
                
            </Container>

            <div className="col-lg-4 col-6" style={{paddingLeft: 0, marginBottom: '15px'}}>
                <SmallBox
                    count={alertsStore.selectedEndpointTotal}
                    title="Alerts"
                    src={alertsIcon}
                    alt="alertsIcon"
                />
            </div>


            <div className='lower-div'>
                <TableTitle>Alerts</TableTitle>

                <Container
                    disableGutters
                    style={{
                        maxWidth: 'inherit',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px'
                    }}
                >
                    <FiltersBar
                        filters={filters}
                        onFiltersChange={handleFilterChange}/>
                    <Button
                        variant='text'
                        onClick={handleDownloadCSV}
                        style={{ float: 'right' }}
                        startIcon={<FileDownloadIcon />}>
                        Download Alerts CSV
                    </Button>
                </Container>

                <AlertsTable
                    alerts={alertsStore.selectedEndpointAlerts}
                    totalAlertsCount={alertsStore.selectedEndpointTotal}
                    loading={alertsStore.loading}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setRowsPerPage}
                    onSort={handleSort}
                    rowsPerPage={rowsPerPage}
                    order={order}
                    orderBy={sortBy}
                    severityFilter={severitiesFilter}
                    categoryFilter={categoriesFilter}
                    onClickOnTag={handleClickOnTag}/>

            </div>

        </ScreenContainer>
    )
}
