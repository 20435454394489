import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStoresContext } from 'stores/StoresProvider';

type AuthenticatedRouteProps = {
    requiresAdmin?: boolean
    requiresSupervisor?: boolean
    children: any
}

export default function AuthenticatedRoute({ requiresAdmin, requiresSupervisor, children }: AuthenticatedRouteProps) {
    const { authStore: AuthStore } = useStoresContext()

    if (!AuthStore.isAuthenticated)
        return (<Navigate replace to="/login" />)

    if (requiresAdmin && !AuthStore.isAdmin)
        return (<Navigate replace to="/" />)
    else if (requiresSupervisor && !AuthStore.isSupervisor)
        return (<Navigate replace to="/" />)

    return (<>{children}</>);
}
