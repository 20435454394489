import { createContext, useContext } from 'react'
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { initializeApp } from 'firebase/app';


interface SingleSignOnStore {
    signInPopUp: () => Promise<string>
}

const SingleSignOnContext = createContext<SingleSignOnStore | undefined>(undefined)

export function useSingleSignOnStoreContext() {
    const stores = useContext(SingleSignOnContext)
    if (stores === undefined)
        throw new Error('useSingleSignOnStoreContext must be use wrapped around a SingleSignOnProvider')
    return stores
}

export default function SingleSignOnProvider({ children }: {children: any}) {
    const provider = new GoogleAuthProvider()
    const app = initializeApp(getFirebaseConfig())
    const auth = getAuth(app)

    async function signInPopUp() {
        return signInWithPopup(auth, provider)
        .then(result => {
            if (result) {
                const credential = result.user.getIdToken()
                if (credential)
                    return credential
            }
            return Promise.reject(`Unable to sign in with popup. result=${result}`)
        }).catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            const email = error.customData.email
            const credential = GoogleAuthProvider.credentialFromError(error)
            console.error('Error while signing in with popup', {errorCode, errorMessage, email, credential})
            throw error
        })
    }

    return (
        <SingleSignOnContext.Provider value={{
            signInPopUp
        }}>
            {children}
        </SingleSignOnContext.Provider>
    )
}

function getFirebaseConfig() {
    const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY
    const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
    const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
    const firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
    const firebaseMessagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
    const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID
    const firebaseMeasurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

    return {
        apiKey: firebaseApiKey ? firebaseApiKey : "AIzaSyDY0OdZhXAIXJt1UU13AU9ih3MNz3KYJ5s",
        authDomain: firebaseAuthDomain ? firebaseAuthDomain : "phalanx-webapp.firebaseapp.com",
        projectId: firebaseProjectId ? firebaseProjectId : "phalanx-webapp",
        storageBucket: firebaseStorageBucket ? firebaseStorageBucket : "phalanx-webapp.appspot.com",
        messagingSenderId: firebaseMessagingSenderId ? firebaseMessagingSenderId :  "174471571798",
        appId: firebaseAppId ? firebaseAppId : "1:174471571798:web:c375ef3b8c229fa04b0ef7",
        measurementId: firebaseMeasurementId ? firebaseMeasurementId : "G-DG55QKV3QY"
    }
}
