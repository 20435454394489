import React from 'react';
import './NotFound.scss';

import Card from '../../components/Card';

function NotFound() {
    return (
        <div className="NotFound">
            <Card>
                <h1>
                    404 Not Found
                </h1>

            </Card>
        </div>
    );
  }

  export default NotFound;