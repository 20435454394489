import { TextField } from "@mui/material"
import StyledDialog from "components/StyledDialog"
import PROPERTIES from "properties"
import { useState } from "react"
import { useWindowsConfigsStoreContext } from "stores/WindowsConfigsProvider"
import { toastFail, toastSuccess } from "utils/Toast"

interface AddExclusionListModalProps {
    open: boolean
    onClose: () => void
    accountId: string
}

const { INCLUSION_LISTS } = PROPERTIES

export default function AddInclusionListModal({ open, onClose, accountId}: AddExclusionListModalProps) {
    const [name, setName] = useState<string>('')
    const [programs, setPrograms] = useState<string>('')

    const windowsConfigsStore = useWindowsConfigsStoreContext()

    function onSave() {
        windowsConfigsStore.create({
            name,
            protected_processes: programs
        }, accountId)
        .then(() => {
            toastSuccess('Policy saved!')
            windowsConfigsStore.loadData(accountId)
            onClose()
        })
        .catch((e:Error) => {
            toastFail(e.message)   
        })
    }

    return (
        <StyledDialog 
            open={open}
            onClose={onClose}
            title={INCLUSION_LISTS.DIALOG.TITLE}
            onClickPrimary={onSave}
            primaryText={INCLUSION_LISTS.DIALOG.SAVE}
            content={
                <>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        label={INCLUSION_LISTS.DIALOG.LIST_NAME}
                        type="text"
                        fullWidth
                        variant="filled"
                        style={{marginBottom: '10px'}}
                    />
                    <TextField 
                        required
                        margin="dense"
                        value={programs}
                        onChange={event => setPrograms(event.target.value)}
                        fullWidth
                        label={INCLUSION_LISTS.DIALOG.LIST_OF_PROGRAMS}
                        variant="filled"
                        multiline
                        rows={4}/>
                </>
            }/>
    )
}