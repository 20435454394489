import React from 'react';
import { inject, observer } from 'mobx-react';
import AccountsTable from './AccountsTable';
import EditUserModal from './EditAccountModal';
import './Accounts.scss';
import { ScreenContainer } from '../settings/Settings';
import { TitleContainer } from '../home/Home';
import { Button } from '@mui/material';

type UsersProps = {
    AccountsStore?: any;
    AuthStore?: any;
};

class Accounts extends React.Component<UsersProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            account: '',
            role: '',
            id: '',
        };
    }

    componentDidMount() {
        const { AccountsStore,AuthStore } = this.props;
        AccountsStore.getAllAcounts();
        AuthStore.loadMyAccount();
    }
    
    onAddAccount = () => {
        const { AccountsStore } = this.props;
        AccountsStore.setSelectedAccount({});
    }

    onAssignAccountId = (id:any) => {
        this.setState({account: id})
    }

    render() {
        const { AccountsStore, AuthStore } = this.props;
        const { selectedAccount } = AccountsStore;
        const { accounts } = AccountsStore;

        if(AuthStore.isSupervisor){
            AccountsStore.accounts?.items?.forEach((item:any) => {
                item['account_name'] = item.name
            });
        }

        return (
            <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}} className="Users">
                <TitleContainer style={{alignItems: 'flex-end'}}>
                    <div>
                        <h1>Account Administration</h1>
                        <span className="total-users">Total Accounts: {accounts.length}</span>
                    </div>
                    { AuthStore.parentAccount === "" && 
                        <div>
                            <Button
                                variant='text'
                                style={{ height: '50px', marginBottom: '-10px' }}
                                onClick={this.onAddAccount}>
                                <i className={'fas fa-plus-circle whiteicolor'} style={{ color: '#dc3545', marginRight: 10 }}></i>
                                Add Account
                            </Button>
                        </div>
                    }
                </TitleContainer>


                <AccountsTable accounts={accounts} loadingState={AccountsStore.loading}/>

                {selectedAccount && <EditUserModal />}
                
            </ScreenContainer>
        );
    }
}


export default inject('AccountsStore','AuthStore')(observer(Accounts));
