import React from 'react';


// import Messages from './messages/Messages';
// import Notifications from './notifications/Notifications';
import User from './user/User';

const Header = () => {
    return (
        <nav className="main-header navbar navbar-expand navbar-black navbar-dark" style={{backgroundColor: '#222227', border:'1px #32334A'}} >
            <ul className="navbar-nav ml-auto">
                <User />
            </ul>
        </nav>
    );
};

export default Header;
