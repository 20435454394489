import moment from 'moment';

export const pastYearMonthsArray = () => {

    const months:any = [];
    const startDate = moment().subtract(12, "months");

    for (let i=0; i<12; i++) {
        startDate.add(1, 'month');

        const objMonth = {
            label: startDate.format('MMM'),
            value: 0,
        }

        months[startDate.format('YYYYMM')] = objMonth;
    }

    return months;

}