import { createContext, useContext, useState } from "react";
import { makeAuthenticatedRequest } from "utils/API";

interface ProcessesStore {
    processesCount: number
    loadProcessesCount: () => void
}

const ProcessesContext = createContext<ProcessesStore | undefined>(undefined)

export function useProcessesStoreContext() {
    const stores = useContext(ProcessesContext)
    if (stores === undefined)
        throw new Error('useProcessesStoreContext must be use wrapped around a ProcessesProvider')
    return stores
}

export default function ProcessesProvider({children}: {children: any}) {
    const [processesCount, setProcessesCount] = useState(0)

    function loadProcessesCount() {
        return makeAuthenticatedRequest({url: `/api/v1/processes?sort=last_heartbeat&count=0`})
            .then(onLoadProccessCount)
    }

    function onLoadProccessCount({ total, items}: {total: number, items: never}) {
        setProcessesCount(total)
    }

    return (
        <ProcessesContext.Provider value={{
            processesCount,
            loadProcessesCount
        }}>
            { children }
        </ProcessesContext.Provider>
    )
}