import React from 'react';

interface CardProps { }

const Card = ({ children }: React.PropsWithChildren<CardProps>) => (
    <div className="card card-outline" style={{ backgroundColor: '#1e1e20', margin: '2rem'}}>
        <div className="card-body">
            {children}
        </div>
    </div>
);

export default Card;