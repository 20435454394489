import { createContext, useContext, useState } from "react"
import { makeAuthenticatedRequest } from "utils/API"
import { Device, Metadata } from "./DevicesStore"
import { Order } from "./AlertsStore"

const LIVE_ENDPOINT_LAST_TRAILING_DAYS = 14

export type DeviceKey = 'ip' | 'hostname' | 'agent_version'

interface DevicesStore {
    loading: boolean
    desktops: Device[]
    desktopsCount: number
    desktopsCursorCurrent: string
    desktopsCursorBefore: string
    desktopsCursorAfter: string
    desktopsCursorLast: string
    desktopsOrder: Order
    desktopsOrderBy: DeviceKey
    servers: Device[]
    serversCount: number
    serversCursorCurrent: string
    serversCursorBefore: string
    serversCursorAfter: string
    serversCursorLast: string
    serversOrder: Order
    serversOrderBy: DeviceKey
    containersCount: number
    liveEndpointsCount: number
    loadDesktopData: (count: number, cursor: string, accountId?: string) => void
    setDesktopsOrder: (order: Order) => void
    setDesktopsOrderBy: (orderBy: DeviceKey) => void
    loadServerData: (count: number, cursor: string, accountId?: string) => void
    setServersOrder: (order: Order) => void
    setServersOrderBy: (orderBy: DeviceKey) => void
    loadContainerData: (count: number, cursor: string, accountId?: string) => void
    loadLiveEndpointsCount: (accountId?: string) => void
    deleteDevice: (device: Device) => Promise<void>
}

type DevicesResponse = {
    items: Device[],
    total: number,
    metadata: Metadata
}

const DevicesContext = createContext<DevicesStore | undefined>(undefined)

export function useDevicesStoreContext() {
    const stores = useContext(DevicesContext)
    if (stores === undefined)
        throw new Error('useDevicesStoreContext must be use wrapped around a DevicesProvider')
    return stores
}

export type LoadOptions = {
    orderBy?: DeviceKey
    order?: Order
    selectedAccountId?: string
}

type DeviceType = 'server' | 'desktop' | 'container'

export default function DevicesProvider({children}: {children: any}) {
    const [loading, setLoading] = useState<boolean>(false)
    const [desktops, setDesktops] = useState<Device[]>([])
    const [desktopsCount, setDesktopsCount] = useState<number>(0)
    const [desktopsCursorCurrent, setDesktopsCursorCurrent] = useState<string>('')
    const [desktopsCursorBefore, setDesktopsCursorBefore] = useState<string>('')
    const [desktopsCursorAfter, setDesktopsCursorAfter] = useState<string>('')
    const [desktopsCursorLast, setDesktopsCursorLast] = useState<string>('')
    const [desktopsOrder, setDesktopsOrder] = useState<Order>('asc')
    const [desktopsOrderBy, setDesktopsOrderBy] = useState<DeviceKey>('hostname')
    const [servers, setServers] = useState<Device[]>([])
    const [serversCount, setServersCount] = useState<number>(0)
    const [serversCursorCurrent, setServersCursorCurrent] = useState<string>('')
    const [serversCursorBefore, setServersCursorBefore] = useState<string>('')
    const [serversCursorAfter, setServersCursorAfter] = useState<string>('')
    const [serversCursorLast, setServersCursorLast] = useState<string>('')
    const [serversOrder, setServersOrder] = useState<Order>('asc')
    const [serversOrderBy, setServersOrderBy] = useState<DeviceKey>('hostname')
    const [containersCount, setContainersCount] = useState<number>(0)
    const [liveEndpointsCount, setLiveEndpointsCount] = useState<number>(0)
    
    async function loadDevicesData(count: number, cursor: string, deviceType: DeviceType, setDevices: (devices: Device[]) => void, setCount: (count: number) => void, setCursorCurrent: (cursor: string) => void, setCursors: (metadata: Metadata) => void, order: Order, orderBy: DeviceKey, accountId?: string) {
        setLoading(true)
        setCursorCurrent(cursor)

        // Build url
        const sort = `${order === 'desc' ? '-' : ''}${orderBy}`
        let url = '/api/v2/devices'
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/devices`
        url = url.concat(`?sort=${sort}&count=${count}&cursor=${cursor}&device_type=${deviceType}&include_subaccounts=true`)

        return makeAuthenticatedRequest({ url })
            .then((res: DevicesResponse) => {
                setDevices(res.items)
                setCount(res.total)
                setCursors(res.metadata)
                setLoading(false)
            })
            .catch(onApiFailure)
    }
    
    async function loadDesktopData(count: number, cursor: string, accountId?: string) {
        return loadDevicesData(count, cursor, 'desktop', setDesktops, setDesktopsCount, setDesktopsCursorCurrent, setDesktopsCursors, desktopsOrder, desktopsOrderBy, accountId)
    }
    
    async function loadServerData(count: number, cursor: string, accountId?: string) {
        return loadDevicesData(count, cursor, 'server', setServers, setServersCount, setServersCursorCurrent, setServersCursors, serversOrder, serversOrderBy, accountId)
    }

    async function loadContainerData(count: number, cursor: string, accountId?: string) {
        // Build url
        let url = '/api/v2/devices'
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/devices`
        url = url.concat(`?count=${count}&cursor=${cursor}&device_type=container&include_subaccounts=true`)

        return makeAuthenticatedRequest({ url })
            .then((res: DevicesResponse) => {
                setContainersCount(res.total)
            })
            .catch(onApiFailure)
    }

    async function loadLiveEndpointsCount(accountId?: string) {
        // Build url
        let url = '/api/v2/devices'
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/devices`
        const now = new Date()
        now.setDate(now.getDate() - LIVE_ENDPOINT_LAST_TRAILING_DAYS)
        url = url.concat(`?last_heartbeat_from=${now.toISOString()}&include_subaccounts=true&count=1`)

        return makeAuthenticatedRequest({ url })
            .then(onLoadLiveEnpointsSuccess)
            .catch(onApiFailure)
    }

    function onLoadLiveEnpointsSuccess(response: DevicesResponse) {
        setLiveEndpointsCount(response.total)
    }

    function setDesktopsCursors(metadata: Metadata) {
        setDevicesCursors(metadata, setDesktopsCursorAfter, setDesktopsCursorBefore, setDesktopsCursorLast)
    }

    function setServersCursors(metadata: Metadata) {
        setDevicesCursors(metadata, setServersCursorAfter, setServersCursorBefore, setServersCursorLast)
    }

    function setDevicesCursors(metadata: Metadata, setCursorAfter: (cursor: string) => void, setCursorBefore: (cursor: string) => void, setCursorLast: (cursor: string) => void) {
        if (metadata != null) {
            if (metadata.cursor_after != null)
                setCursorAfter(metadata.cursor_after)
            else
                setCursorAfter('')
            if (metadata.cursor_before != null)
                setCursorBefore(metadata.cursor_before)
            else
                setCursorBefore('')
            if (metadata.cursor_last != null)
                setCursorLast(metadata.cursor_last)
            else
                setCursorLast('')
        }
    }

    async function deleteDevice(device: Device) {
        setLoading(true)
        let url = `/api/v2/accounts/${device.account_id}/devices/${device.id}`
        if (!device.account_id)
            url = `/api/v1/users/${device.user_id}/devices/${device.id}`

        return makeAuthenticatedRequest({
            url,
            options: { method: 'DELETE' }
        })
        .catch(onApiFailure)
    }

    function onApiFailure(e: Error) {
        setLoading(false)
        throw e
    }

    return (
        <DevicesContext.Provider value={{
            loading,
            desktops,
            desktopsCount,
            desktopsCursorCurrent,
            desktopsCursorBefore,
            desktopsCursorAfter,
            desktopsCursorLast,
            desktopsOrder,
            desktopsOrderBy,
            servers,
            serversCount,
            serversCursorCurrent,
            serversCursorBefore,
            serversCursorAfter,
            serversCursorLast,
            containersCount,
            serversOrder,
            serversOrderBy,
            liveEndpointsCount,
            loadServerData,
            loadDesktopData,
            loadContainerData,
            loadLiveEndpointsCount,
            setDesktopsOrder,
            setDesktopsOrderBy,
            setServersOrder,
            setServersOrderBy,
            deleteDevice
        }}>
            { children }
        </DevicesContext.Provider>
    )
}
