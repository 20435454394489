import { createContext, useContext } from "react";
import { AccountsStore as AccountsStoreClass } from "./AccountsStore";
import AccountsStore from './AccountsStore'
import { AuthStore as AuthStoreClass } from "./AuthStore";
import AuthStore from './AuthStore'
import { DevicesStore as DevicesStoreClass } from "./DevicesStore";
import DevicesStore from './DevicesStore'
import { AlertsStore as AlertsStoreClass } from "./AlertsStore";
import AlertsStore from './AlertsStore'
import { UsersStore as UsersStoreClass} from "./UsersStore";
import UsersStore from "./UsersStore"
import { WindowsConfigStore as WindowsConfigStoreClass } from "./WindowsConfigStores";
import WindowsConfigStore from './WindowsConfigStores'
import { DeviceDetailStore as DeviceDetailStoreClass } from "./DeviceDetailStore";
import DeviceDetailStore from './DeviceDetailStore'

interface Stores {
    accountsStore: AccountsStoreClass
    authStore: AuthStoreClass
    devicesStore: DevicesStoreClass
    alertsStore: AlertsStoreClass
    usersStore: UsersStoreClass
    windowsConfigStore: WindowsConfigStoreClass
    deviceDetailStore: DeviceDetailStoreClass
}

const StoresContext = createContext<Stores | undefined>(undefined)

export function useStoresContext() {
    // This avoid using the storesContext if the component isn't wrapped around a StoresProvider
    const stores = useContext(StoresContext)
    if (stores === undefined)
        throw new Error('useStoresContext must be use wrapped around a StoresProvider')
    return stores
}

interface StoresProviderProps {
    children: any
}

export default function StoresProvider({ children }: StoresProviderProps) {
    return (
        <StoresContext.Provider value={{
            accountsStore: AccountsStore,
            authStore: AuthStore,
            devicesStore: DevicesStore,
            alertsStore: AlertsStore,
            usersStore: UsersStore,
            windowsConfigStore: WindowsConfigStore,
            deviceDetailStore: DeviceDetailStore
        }}>
            { children }
        </StoresContext.Provider>
    )
}