import React from 'react';

import './LoadingComponent.scss';

const LoadingComponent = () => {
    return (
        <div className="loader"></div>
    )
}

export default LoadingComponent;