import React, { useState } from 'react';
import './WindowsConfigsList.scss'
import { toastFail, toastSuccess } from 'utils/Toast';
import { Button, Container, IconButton, Tooltip, styled } from '@mui/material';
import PROPERTIES from 'properties';
import { WindowsConfig, useWindowsConfigsStoreContext } from 'stores/WindowsConfigsProvider';
import StyledDialog from 'components/StyledDialog';
import StyledMultiSelect from 'components/StyledMultiSelect';
import { useAccountsStoreContext } from 'stores/AccountsProvider';
import { TbFolderShare } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import { Account } from 'stores/AuthStore';

const { INCLUSION_LISTS } = PROPERTIES

type Sorting = 'unfiltered' | 'ascending' | 'descending'

interface WindowsConfigsListProps {
    accountId: string
} 

export default function WindowsConfigsList({ accountId }: WindowsConfigsListProps) {
    const [sorting, setSorting] = useState<Sorting>('unfiltered')
    const [showShareDialog, setShowShareDialog] = useState<boolean>(false)
    const [accountsIdsSharedWith, setAccountsIdsSharedWith] = useState<string[]>([])
    const [selectedWindowsConfig, setSelectedWindowsConfig] = useState<WindowsConfig>()

    const windowsConfigsStore = useWindowsConfigsStoreContext()
    const { accountsById } = useAccountsStoreContext()

    function onDeleteConfig(config: WindowsConfig) {
        if(config.name === "default") {
            toastFail('Cannot delete default inclusion list')
            return
        }
   
        return windowsConfigsStore.deleteConfig(config)
    }

    async function onClickSetToAllEndpoints(config: WindowsConfig): Promise<void> {
        windowsConfigsStore.setConfigToAllEndpoints(accountId, config.id)
        .then(() => toastSuccess(INCLUSION_LISTS.SNACKBAR_SET.SUCCESS))
        .catch((error) => {
            console.error(error)
            toastFail(`${INCLUSION_LISTS.SNACKBAR_SET.ERROR}: ${error}`)
        })
    }

    function onClickShareWithSubaccounts(config: WindowsConfig) {
        const accountsIds = config.shared_with ? config.shared_with.map((account) => account.account_id) : []
        setAccountsIdsSharedWith(accountsIds)
        setShowShareDialog(true)
        setSelectedWindowsConfig(config)
    }

    async function shareWithSubaccounts(): Promise<void> {
        if (selectedWindowsConfig)
            windowsConfigsStore.shareConfigWithSubaccounts(selectedWindowsConfig.id, accountsIdsSharedWith)
            .then(() => windowsConfigsStore.loadData(accountId))
            .then(() => toastSuccess(INCLUSION_LISTS.SNACKBAR_SHARE.SUCCESS))
            .catch((error) => {
                console.error(error)
                toastFail(`${INCLUSION_LISTS.SNACKBAR_SHARE.ERROR}: ${error}`)
            })
    }

    function handleOnChangeMultiSelect(value: string[]) {
        setAccountsIdsSharedWith(value)
    }

    function renderSelectedAccounts(selected: string[]) {
        return selected.map((accountId) => accountsById.get(accountId)?.name).sort().join(', ')
    }

    let multiSelectOptions: {value: string, label?: string}[] = []
    if (accountsById.has(accountId) &&  accountsById.get(accountId)?.sub_accounts)
        multiSelectOptions = (accountsById.get(accountId)?.sub_accounts as Account[])
            .map((account) => {
                return {value: account.id, label: account.name}
            })

    const ascending = JSON.parse(JSON.stringify(windowsConfigsStore.windowsConfigs)).sort((a: any, b: any) => { return a.name.localeCompare(b.name) })
    const descending = JSON.parse(JSON.stringify(windowsConfigsStore.windowsConfigs)).sort((a: any, b: any) => { return b.name.localeCompare(a.name) })

    const isSubaccountSelected = accountsById.get(accountId)?.parent_account_id !== null

    const dataDirection = (sortingType: any) => {
        switch(sortingType){
            case 'unfiltered':
                return (
                    windowsConfigsStore.windowsConfigs ? windowsConfigsStore.windowsConfigs.map(
                        (config: WindowsConfig, index: number) =>
                            <WindowsConfigRow
                                windowsConfig={config}
                                accountId={accountId}
                                onDelete={onDeleteConfig}
                                onClickSetAllEndpoints={onClickSetToAllEndpoints}
                                onClickShareWithSubaccounts={onClickShareWithSubaccounts}
                                isSubaccountSelected={isSubaccountSelected}
                                key={index} />
                    ) : null 
                )
            case 'descending':
                return (
                    descending ? descending.map(
                        (config: WindowsConfig, index: number) =>
                            <WindowsConfigRow
                                windowsConfig={config}
                                accountId={accountId}
                                onDelete={onDeleteConfig}
                                onClickSetAllEndpoints={onClickSetToAllEndpoints}
                                onClickShareWithSubaccounts={onClickShareWithSubaccounts}
                                isSubaccountSelected={isSubaccountSelected}
                                key={index} />
                    ) : null 
                );
            case 'ascending':
                return (
                    ascending ? ascending.map(
                        (config: WindowsConfig, index: number) =>
                            <WindowsConfigRow
                                windowsConfig={config}
                                accountId={accountId}
                                onDelete={onDeleteConfig}
                                onClickSetAllEndpoints={onClickSetToAllEndpoints}
                                onClickShareWithSubaccounts={onClickShareWithSubaccounts}
                                isSubaccountSelected={isSubaccountSelected}
                                key={index} />
                    ) : null 
                )
            default:
                return (
                    windowsConfigsStore.windowsConfigs ? windowsConfigsStore.windowsConfigs.map(
                        (config: WindowsConfig, index: number) =>
                            <WindowsConfigRow
                                windowsConfig={config}
                                accountId={accountId}
                                onDelete={onDeleteConfig}
                                onClickSetAllEndpoints={onClickSetToAllEndpoints}
                                onClickShareWithSubaccounts={onClickShareWithSubaccounts}
                                isSubaccountSelected={isSubaccountSelected}
                                key={index} />
                    ) : null 
                )
        }
    }

    return (
        <div className="frequency-component" style={{overflowY: 'scroll', height: 'auto'}}>
            <Container sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <button className="frequency-button">
                    <span className='sort-by'>Sort by:</span> 
                    <span className='frequency'>Name</span>
                    <i 
                        className={'fa fa-arrow-down'} 
                        style={{ color: 'white', marginRight: 5, cursor: 'pointer'}}
                        onClick = { () => setSorting('descending')}
                    >
                    </i>
                    <i 
                        className={'fa fa-arrow-up'} 
                        style={{ color: 'white'}}
                        onClick = { () => setSorting('ascending')}
                        >
                    </i>
                </button>
            </Container>
            <br/>

            <div className="Card" row-span={2}>
                {dataDirection(sorting)}
            </div>

            <StyledDialog
                open={showShareDialog}
                onClose={() => setShowShareDialog(false)}
                title='Share with subaccounts'
                disablePrimary={false}
                onClickPrimary={shareWithSubaccounts}
                primaryText='Share'
                content={
                    <>
                        <p>This Windows configuration will be shared with the following subaccounts:</p>
                        <StyledMultiSelect
                            label="Shared with"
                            value={accountsIdsSharedWith}
                            handleOnChange={handleOnChangeMultiSelect}
                            sx={{'& .MuiFormControl-root': {width: '100%'}}}
                            options={multiSelectOptions}
                            renderValue={renderSelectedAccounts} />
                    </>
                }/>
        </div>
    )
}

interface WindowsConfigRowProps {
    windowsConfig: WindowsConfig
    accountId: string
    onDelete: (config: WindowsConfig) => void
    onClickSetAllEndpoints: (config: WindowsConfig) => void
    onClickShareWithSubaccounts: (config: WindowsConfig) => void
    isSubaccountSelected?: boolean
}

function WindowsConfigRow({ windowsConfig, accountId, onDelete, onClickSetAllEndpoints, onClickShareWithSubaccounts, isSubaccountSelected }: WindowsConfigRowProps) {
    const sharedAccountsIds = windowsConfig.shared_with ? windowsConfig.shared_with?.map((config) => config.account_id) : []
    const sharedAccountsNames = windowsConfig.shared_with ? windowsConfig.shared_with?.map((config) => config.account_name) : []
    const isSharedWithSome = windowsConfig.shared_with && windowsConfig.shared_with.length > 0
    const isSharedWithThisAccount = sharedAccountsIds.includes(accountId)
    const createdByAnotherAccount = windowsConfig.account_id && windowsConfig.account_id !== accountId

    return (
        <RowContainer>
            <ConfigContainer>
                <div style={{width: '93%', marginBottom: '10px'}}>
                    <h4><b>{windowsConfig.name}</b></h4>
                    <p>Allowlist: <span>{windowsConfig.allowlist}</span></p>
                    { isSharedWithSome && !isSharedWithThisAccount &&
                        <p>Shared with: <span>{sharedAccountsNames.sort().join(', ')}</span></p>
                    }
                    { isSharedWithSome && isSharedWithThisAccount &&
                        <p>Shared from parent: <span>{windowsConfig.account_name}</span></p>
                    }
                    { createdByAnotherAccount && !isSubaccountSelected &&
                        <p>Created by: <span>{windowsConfig.account_name}</span></p>
                    }
                </div>
                <IconsContainer>
                    { windowsConfig.name !== 'default' &&
                        <>
                            { !isSubaccountSelected && !createdByAnotherAccount &&
                                <Tooltip title={'Share with subaccounts'}>
                                    <IconButton onClick={() => onClickShareWithSubaccounts(windowsConfig)}>
                                        <TbFolderShare size={20}/>
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title={'Delete'}>
                                <IconButton onClick={() => onDelete(windowsConfig)}>
                                    <MdDeleteOutline />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                </IconsContainer>
            </ConfigContainer>
            <ButtonContainer disableGutters>
                <Button
                    variant='text'
                    sx={{display: 'inline'}}
                    onClick={() => onClickSetAllEndpoints(windowsConfig)}
                >
                    {INCLUSION_LISTS.SET_TO_ENDPOINT}
                </Button>
            </ButtonContainer>
        </RowContainer>
    )
}

const RowContainer = styled(Container)`
    margin-top: 1rem;
    display: flex;
    align-items: center;
`

const ConfigContainer = styled('div')`
    display: flex;
    align-items: center;
    margin-left: 2rem;
    background-color: #222227;
    min-width: 20%;
    width: 96%;
    min-height: 80px;
    border-radius: 6px;
    padding-left: 10px;

    &:hover {
        box-shadow: 0 1px 10px 1px rgba(186, 182, 182, 0.2);
    }
`

const IconsContainer = styled('div')`
    display: flex;
    width: 7%;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 13px;
`

const ButtonContainer = styled(Container)`
    width: auto;
    padding-left: 20px;
`
