import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

interface StyledDialogProps {
    open: boolean
    onClose: () => void
    title: string
    content: any
    disablePrimary?: boolean
    onClickPrimary: () => void
    primaryText: string
}

export default function StyledDialog({ open, onClose, title, content, disablePrimary, onClickPrimary, primaryText }: StyledDialogProps) {

    function handleClickPrimary() {
        onClickPrimary()
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth={'md'}
        >
            <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
            <DialogContent>{ content }</DialogContent>
            <DialogActions>
                <Button variant={'text'} onClick={onClose}>Cancel</Button>
                <Button variant={'contained'} disabled={disablePrimary} onClick={handleClickPrimary}>{primaryText}</Button>
            </DialogActions>
        </Dialog>
    )
}
