import { Autocomplete, TextField, Checkbox, Box } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useEffect, useState } from "react";
import { Category, Mitigation, Severity } from "stores/AlertsProvider";

type Option<T> = {
    value: T
    label: string
}

export type Filters = {
    severity: Severity[]
    category: Category[]
    mitigation: Mitigation[]
}

const severitiesFilterOptions: Option<Severity>[] = [
    { value: 'ransomware', label: 'Ransomware'},
    { value: 'ids', label: 'Intrusion'},
    { value: 'siem', label: 'SIEM'},
]

const categoriesFilterOptions: Option<Category>[] = [
    { value: 'hardening', label: 'Prevention'},
    { value: 'detection', label: 'Detection'},
    { value: 'mitigation', label: 'Recovery'},
]

const mitigationFilterOptions: Option<Mitigation>[] = [
    { value: 'blocked', label: 'Blocked'},
    { value: 'unblocked', label: 'Not blocked'},
]


interface FiltersBarProps {
    filters: Filters
    onFiltersChange: (filters: Filters) => void
}

export default function FiltersBar({ filters, onFiltersChange }: FiltersBarProps)  {
    function getOptionFromFilter<FilterType>(filters: FilterType[], options: Option<FilterType>[]) {
        return options.filter(opt => filters.includes(opt.value))
    }

    function onChangeSeverity(event: any, newValue: Option<Severity>[]) {
        onFiltersChange({
            severity: newValue.map(option => option.value),
            category: filters.category,
            mitigation: filters.mitigation
        })
    }
    
    function onChangeCategory(event: any, newValue: Option<Category>[]) {
        onFiltersChange({
            severity: filters.severity,
            category: newValue.map(option => option.value),
            mitigation: filters.mitigation
        })
    }

    function onChangeMitigation(event: any, newValue: Option<Mitigation>[]) {
        onFiltersChange({
            severity: filters.severity,
            category: filters.category,
            mitigation: newValue.map(option => option.value)
        })
    }

    function renderOption<T>(props: any, option: Option<T>, { selected }: { selected: boolean}) {
        const {key, ...otherProps} = props
        return (
            <li {...otherProps} key={key} >
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon/>}
                    checkedIcon={<CheckBoxIcon/>}
                    style={{ marginRight: 8 }}
                    checked={selected}/>
                {option.label}
            </li>
        )
    }

    return (
        <Box sx={{display: 'flex'}}>
            <Autocomplete
                multiple
                value={getOptionFromFilter(filters.severity, severitiesFilterOptions)}
                onChange={onChangeSeverity}
                options={severitiesFilterOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={renderOption<Severity>}
                renderInput={(params) => (
                    <TextField {...params} label="Severity" />
                )}
                sx={{maxWidth: '375px', width: '30%', minWidth: '275px', marginRight: '10px'}}
                size="small"
                limitTags={1}/>

            <Autocomplete
                multiple
                value={getOptionFromFilter(filters.category, categoriesFilterOptions)}
                onChange={onChangeCategory}
                options={categoriesFilterOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={renderOption<Category>}
                renderInput={(params) => (
                    <TextField {...params} label="Category" />
                )}
                sx={{maxWidth: '375px', width: '30%', minWidth: '275px', marginRight: '10px'}}
                size="small"
                limitTags={1}/>

            <Autocomplete
                multiple
                value={getOptionFromFilter(filters.mitigation, mitigationFilterOptions)}
                onChange={onChangeMitigation}
                options={mitigationFilterOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={renderOption<Mitigation>}
                renderInput={(params) => (
                    <TextField {...params} label="Mitigation" />
                )}
                sx={{maxWidth: '375px', width: '30%', minWidth: '275px', marginRight: '10px'}}
                size="small"
                limitTags={1}/>
        </Box>
    )
}