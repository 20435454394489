import React, { useState } from 'react';
import DropDownMenu from './DropDownMenu';

type DropDownButtonProps = {
    children: any[];
    containerTag?: string;
    size: 'sm' | 'md' | 'lg';
    iconCls: string;
    badgeCount?: number;
};

const DropDownButton = ({ children, size, containerTag, iconCls, badgeCount }: DropDownButtonProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <>
            <button onClick={toggleDropdown} type="button" className="nav-link">
                <i className={iconCls} />
                <span className="badge badge-danger navbar-badge">{badgeCount}</span>
            </button>
            {dropdownOpen ? (
                <DropDownMenu containerTag={containerTag} size={size}>
                    {children}
                </DropDownMenu>
            ) : null}
        </>
    );
};

export default DropDownButton;
