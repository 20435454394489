import React from 'react';
import './SmallBox.scss'

type SmallBoxProps = {
    style?: any;
    count: string | number;
    title: any;
    src?: string,
    alt?: string,
    muiIcon?: any
};

const SmallBoxSingleEndpoint = ({
    count,
    title,
    style = {
        backgroundColor: 'rgba(186, 182, 182, 0.2)',
    },
    src,
    alt,
    muiIcon
}: SmallBoxProps) => (
    <div className={`small-box small-box-custom-styling`}>
        <div className="icon-styling">
            { src && alt &&
                <div>
                    <img src={`${src}`} alt={`${alt}`}/>
                </div>
            }
            { muiIcon }
        </div>
        <p>{count}</p>
        <p>{title}</p>

    </div>
);

export default SmallBoxSingleEndpoint;

