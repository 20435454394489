import { TableContainer, Paper, TableHead, TableBody, styled, TableRow, Table, Tooltip, Fade, Skeleton } from '@mui/material';
import PROPERTIES from 'properties';
import { useEffect, useState } from 'react';
import { Account } from 'stores/AuthStore';
import { StyledTableRow as DefaultTableRow, StyledTableCell as DefaultTableCell } from '../../../components/table/EnhancedTableHead';

const { ACCOUNTS } = PROPERTIES

type AccountsTableProps = {
    accounts: Account[]
    loadingState?: any
    noDataMessage?: any
};

export const AccountsTableCell = styled(DefaultTableCell)(({ theme }) => ({
    textAlign: 'left'
}))

const StyledTableRow = styled(DefaultTableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

interface AccountsTableLoaderProps {
    rowsNum: number
}

function AccountsTableLoader ({rowsNum}: AccountsTableLoaderProps) {
    return (
        <>
            {[...Array(rowsNum)].map((row, index) => (
                <StyledTableRow key={index}>
                    {[...Array(3)].map((column, index) => (
                        <AccountsTableCell>
                            <Skeleton width={200} animation="wave" variant="text" />
                        </AccountsTableCell>
                    ))}
                    {[...Array(3)].map((column, index) => (
                        <AccountsTableCell>
                            <Skeleton animation="wave" variant="text" />
                        </AccountsTableCell>
                    ))}
                </StyledTableRow>
            ))}
        </>
    )
  };

export default function AccountsTable ({accounts: accs, loadingState, noDataMessage}: AccountsTableProps) {

    const [accounts, setAccounts] = useState<Account[]>(accs)

    // Update accounts whenever accs property is updated
    useEffect(() => {
        setAccounts(accs)
    }, [accs])

    const fields = [
        { name: ACCOUNTS.NAME },
        { name: ACCOUNTS.SUBSCRIPTION_MONTHS, tooltip: ACCOUNTS.TOOLTIP.SUBSCRIPTION_MONTHS},
        { name: ACCOUNTS.LAST_SUBSCRIPTION, tooltip: ACCOUNTS.TOOLTIP.LAST_SUBSCRIPTION},
        { name: ACCOUNTS.USERS_LIMIT, tooltip: ACCOUNTS.TOOLTIP.USERS_LIMIT},
        { name: ACCOUNTS.DEVICES_LIMIT, tooltip: ACCOUNTS.TOOLTIP.DEVICES_LIMIT}
    ]

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        { fields.map(({ name, tooltip }, index) => {
                            return (
                                <AccountsTableCell>
                                    <Tooltip key={index} title={tooltip} arrow TransitionComponent={Fade}>
                                        <span>{name}</span>
                                    </Tooltip>
                                </AccountsTableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { loadingState ? (
                        <AccountsTableLoader rowsNum={3}/>
                    ):
                        accounts.map((account, index) => (
                            <StyledTableRow key={account.name}>
                                <AccountsTableCell component="th" scope="row">{account.name}</AccountsTableCell>
                                <AccountsTableCell>{account.subscription_months}</AccountsTableCell>
                                <AccountsTableCell>{account.date_last_subscription}</AccountsTableCell>
                                <AccountsTableCell>{account.users_limit}</AccountsTableCell>
                                <AccountsTableCell>{account.devices_limit}</AccountsTableCell>
                            </StyledTableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
