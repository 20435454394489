import React, { useEffect, useState } from 'react';
import JSZip from "jszip";
import ReactMarkdown from 'react-markdown';
import { Tabs, Tab } from 'react-bootstrap';
import { toastFail,toastSuccess } from 'utils/Toast';
import './Setup.scss';
import PROPERTIES from 'properties';
import { useStoresContext } from 'stores/StoresProvider';
import { UsersResponse } from 'stores/UsersStore';
import { Button, Checkbox, Container, FormControlLabel, FormGroup } from '@mui/material';
import StyledDialog from 'components/StyledDialog';
import { useAccountsStoreContext } from 'stores/AccountsProvider';
import StyledSelect from 'components/StyledSelect';
import { TitleContainer } from '../home/Home';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Account } from 'stores/AuthStore';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScreenContainer, TabTitle } from '../settings/Settings';

const { DOWNLOAD } = PROPERTIES

const windowsvendormarkdown = `
## Arms Endpoint
- Download ArmsEndpoint.zip by selecting an account for the installer, and then clicking on the Download button
- Extract ArmsEndpoint.zip
- To install silently using your deployment tool, run "./ArmsEndpoint.exe /q /norestart"
- To uninstall silently using your deployment tool, run "./ArmsEndpoint.exe /x // /q MYTOKEN='myapikey'"

### Notes about uninstall
- In the top right of the dashboard go to profile, click create api key, and copy your api key
- On the endpoint, open up powershell and go to the directory of the installer
- Run the following command: "./ArmsEndpoint.exe /x // MYTOKEN='myapikey'"
- In the above command, replace myapikey with your api key. Ensure the api key is between single quotes, to ensure special characters are preserved
`

const linuxvendormarkdown = `
## Arms Endpoint - Linux
- Current Coverage:
- Centos 9 (x86/64)
- Ubuntu 22.04, Ubuntu 24.04 (x86/64)
- If there is a specific distribution you would like to see supported, please reach out to support@armscyber.com

### Installation Instructions
- Download ArmsEndpoint.sh by selecting an account for the installer, and then clicking on the DEB Install or RPM Install button
- Run the following commands to install the agent "sudo bash ArmsEndpoint.sh"
`

const licenseAgreementMarkdown = `
##### **IMPORTANT - READ BEFORE INSTALLING OR USING**

&ZeroWidthSpace;

**Please do not install or use this software until you have carefully read the following
terms and conditions. By using our software, you agree to the terms of this
Agreement.**

&ZeroWidthSpace;

1. **DEFINITIONS.**

    - **(a)** &quot;Software&quot; means the Arms Cyber endpoint agent, to include all past and
subsequent versions.

    - **(b)** &quot;Install&quot; means placing the Software on a computer&#39;s hard disk, CD-ROM or other
secondary storage device.

    - **(c)** &quot;Use&quot; means (i) executing or loading the Software into computer RAM or other
primary memory, and (ii) copying the Software for archival or emergency restart
purposes.
 
2. **TERMINATION.** Licensor shall have the right to immediately terminate this License if
Licensee fails to perform any obligation required of Licensee under this License or if
Licensee becomes bankrupt or insolvent.

3. **RETURN OR DESTRUCTION OF SOFTWARE UPON TERMINATION.** Upon termination
of this License, Licensee shall return to Licensor or destroy the original and all copies of
the Software including partial copies and modifications. Licensor shall have a reasonable
opportunity to conduct an inspection of Licensee&#39;s place of business to assure
compliance with this provision.

4. **TITLE TO SOFTWARE.** Licensor retains title to and ownership of the Software, along
with all enhancements, modifications and updates.

5. **MODIFICATIONS AND ENHANCEMENTS.** Licensee will make no efforts to reverse
engineer the Software, or make any modifications or enhancements without Licensor&#39;s
express written consent.

6. **WARRANTY LIMITATIONS.** THE SOFTWARE IS PROVIDED &quot;AS IS.&quot; LICENSOR
DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO, ALL EXPRESS OR
IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
PURPOSE.

    SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE
ABOVE EXCLUSION MAY NOT APPLY TO YOU.

7. **REMEDY LIMITATIONS.** Licensor&#39;s entire liability and Licensor&#39;s sole and exclusive
remedy for breach of the foregoing warranty shall be Licensor&#39;s option to either:

    - **(a)** return to Licensee the license fee for the period in which the Software did not
perform according to this warranty, or

    - **(b)** repair the defects or replace the Software.

8. **DAMAGE LIMITATIONS.** NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR
INDIRECT, SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES, INCLUDING LOSS OF
PROFITS, AND LICENSOR&#39;S LIABILITY TO LICENSEE FOR ANY OTHER DAMAGES RELATING
TO OR ARISING OUT OF THIS AGREEMENT WHETHER IN CONTRACT, TORT, OR
OTHERWISE WILL BE LIMITED TO THE AMOUNT RECEIVED BY LICENSOR FROM LICENSEE
AS COMPENSATION FOR THE SOFTWARE DURING THE 0 MONTH PERIOD IMMEDIATELY
PRIOR TO THE TIME SUCH CLAIM AROSE.

9. **CONFIDENTIALITY.** Licensee will treat the Software as a trade secret and proprietary
know-how belonging to Licensor that is being made available to Licensee is made in
confidence. Licensee agrees to treat the Software with at least the same care as it treats
its own confidential or proprietary information.

10. **ARBITRATION.** The parties agree to submit any dispute under this License to binding
arbitration under the rules of the American Arbitration Association in Howard County,
Maryland. Judgement upon the award rendered by the arbitrator may be entered in any
court with jurisdiction to do so.

11. **ATTORNEY FEES.** If any legal action is necessary to enforce this License, the
prevailing party shall be entitled to reasonable attorney fees, costs and expenses in
addition to any other relief to which it may be entitled.

12. **GENERAL PROVISIONS.**

    - **(a)** Complete Agreement: This License Agreement together with all schedules
referred to in this Agreement, all of which are incorporated herein by reference,
constitutes the sole and entire Agreement between the parties. This Agreement
supersedes all prior understandings, agreements, representations and documentation
relating to the subject matter of this Agreement.

    - **(b)** Modifications: Modifications and amendments to this Agreement, including
any exhibit, schedule or attachment hereto, shall be enforceable only if in writing and
signed by authorized representatives of both parties.

    - **(c)** Applicable Law: This License will be governed by the laws of the State of
Maryland

    - **(d)** Notices: All notices and other communications given in connection with this
License shall be in writing and shall be deemed given as follows:

        - When delivered personally to the recipient&#39;s address as appearing in the
introductory paragraph to this License;

        - Three days after being deposited in the United States mail, postage prepaid to
the recipient&#39;s address as appearing in the introductory paragraph to this
License; or

        - When sent by fax or telex to the last fax or telex number of the recipient
known to the party giving notice. Notice is effective upon receipt provided that a
duplicate copy of the notice is promptly given by first-class or certified mail or
the recipient delivers a written confirmation of receipt.

        - Any party may change its address appearing in the introductory paragraph to
this License by given notice of the change in accordance with this paragraph

        - No Agency: contained herein will be construed as creating any agency,
partnership, joint venture or other form of joint enterprise between the parties

13. **ASSIGNMENT.** The rights conferred by this License shall not be assignable by the
Licensee without Licensor&#39;s prior written consent. Licensor may impose a reasonable
license fee on any such assignment.
`

function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export default function Setup() {
    const [openAgreement, setOpenAgreement] = useState<boolean>(false)
    const [onClickAgree, setOnClickAgree] = useState<() => void>(() => {})
    const [readAgreement, setReadAgreement] = useState<boolean>(false)

    const accountsStore = useAccountsStoreContext()
    const stores = useStoresContext()

    const { authStore: AuthStore, usersStore: UsersStore} = stores
    const { accounts, selectedAccountIndex } = accountsStore

    const communityEditionEnvVar = process.env.REACT_APP_COMMUNITY_EDITION
    const communityEdition = communityEditionEnvVar ? communityEditionEnvVar.toLowerCase() === 'true' : false

    useEffect(() => {
        AuthStore.loadProfile().catch((e: Error) => toastFail(e.message))
        AuthStore.loadMyAccount()
        accountsStore.loadAccounts()

        // Reset account index if 'All acounts' was selected in previous screen
        if (selectedAccountIndex === -1)
            accountsStore.setSelectedAccountIndex(0)
    }, [])

    function onClickInstall(callback: () => void) {
        return (event: React.MouseEvent<unknown>) => {
            setOpenAgreement(true)
            setOnClickAgree(() => () => {
                callback()
                onCloseAgreement()  
            })
        }
    }

    function onCloseAgreement() {
        setOpenAgreement(false)
        setReadAgreement(false)
    }

    function onClickReadAgreement(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setReadAgreement(checked)
    }
    function download_helper(serviceUserId: string, download_string: string, targetAccountName: string, targetAccountId: string, api_url: string) {
        toast.configure();
        toast.success("Preparing download", { autoClose: 30000 });
        AuthStore.loadUserAPIKey(serviceUserId).then(() => {
            fetch(download_string, {mode: 'cors'}).then((response) => response.blob())
            .then((blobresp) => {
                if(AuthStore.serviceapikey === ""){
                    const message = "Couldn't get api key of service user in account " + targetAccountName
                    toastFail(message)
                }
                else{
                    toastSuccess("Downloading");
                    const serviceapikey = AuthStore.serviceapikey
                    const blob = new Blob([blobresp], {type: "octet/stream"});
                    const mytext = Promise.resolve(blob.arrayBuffer())
                    mytext.then((value) => {
                        const zip = new JSZip();
                        const settings_string = api_url + "\n" + serviceapikey + "\napikey\n" + serviceUserId + "\n" + targetAccountId
                        zip.file("LocalConfig.txt", settings_string);
                        zip.file("ArmsEndpoint.exe", value, {base64: true});
                        var filename = "ArmsEndpoint_for_" + targetAccountName + ".zip"
                        zip.generateAsync({type:"blob"})
                        .then(function(content) {
                            // Force down of the Zip file
                            const url = window.URL.createObjectURL(content);
                            const link = document.createElement("a");
                            link.download = filename;
                            link.href = url;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        });
                        
                    })
                }
            })
        })
    }
    function download_deb_helper(serviceUserId: string, download_string: string, targetAccountName: string, targetAccountId: string, api_url: string) {
        toast.configure();
        toast.success("Preparing download", { autoClose: 30000 });
        AuthStore.loadUserAPIKey(serviceUserId).then(() => {
                if(AuthStore.serviceapikey === ""){
                    const message = "Couldn't get api key of service user in account " + targetAccountName
                    toastFail(message)
                }
                else{
                    toastSuccess("Downloading");
                    const serviceapikey = AuthStore.serviceapikey
                        var script_string = "curl -s https://packagecloud.io/install/repositories/ACD/armsendpoint/script.deb.sh | bash\napt-get update\n"
                        script_string = script_string + "curl -s https://packagecloud.io/install/repositories/ACD/armsendpoint/script.deb.sh | bash\napt-get update\n"
                        script_string = script_string + "[ -d /etc/phalanx_agent ] && sudo rm -rf /etc/phalanx_agent\n"
                        script_string = script_string + "mkdir /etc/phalanx_agent\n"
                        const settings_string = "RAND_PERIOD=0\nSERVER_URL=" + api_url + "/api/v2/\nAPI_TOKEN=" + serviceapikey + "\nACCOUNT_ID=" + targetAccountId;
                        const escaped_settings_string = settings_string.replace(/'/g, "'\"'\"'");
                        script_string = script_string + `echo '${escaped_settings_string}' > /etc/phalanx_agent/.phalanx_conf\n`;
                        script_string = script_string + "apt-get install -y phalanx-agent\n";
                        const filename = "ArmsEndpoint_for_" + targetAccountName + ".sh";
                        const blob = new Blob([script_string], { type: "text/plain" });
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.download = filename;
                        link.href = url;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        
                }
        })
    }
    function onDownloadDeb() {
        if(selectedAccount){
            const download_string = communityEdition ? 
                "https://storage.googleapis.com/winrand-msi/self_install/Current/Community/ArmsEndpoint.exe"
                : "https://storage.googleapis.com/winrand-msi/self_install/Current/ArmsEndpoint.exe"
            const api_url = process.env.REACT_APP_API_URL ?? ''
            const targetAccountId = selectedAccount.id
            const targetAccountName = selectedAccount.name
            const serviceUserEmail = "su@" + targetAccountId + ".com"
            const myuuid = makeid(40);
            const serviceUser = {first_name: "service",last_name: "user",email: serviceUserEmail,role:"admin",password: myuuid,account_id: targetAccountId}
            UsersStore.loadDataById(targetAccountId).then(() => {
                const targetAccountUsers = UsersStore.accountsById as UsersResponse
                let serviceUserId = ""
                for (let i=0; i<targetAccountUsers.items.length; i++){
                    if(targetAccountUsers.items[i].first_name === "service"){
                        if(targetAccountUsers.items[i].last_name === "user"){
                            serviceUserId = targetAccountUsers.items[i].id
                        }
                    }
                }
                if(serviceUserId === ""){
                    AuthStore.createServiceUser(serviceUser).then(() => {
                        UsersStore.loadDataById(targetAccountId).then(() => {
                            const targetAccountUsers2 = UsersStore.accountsById as UsersResponse
                            for (let i=0; i<targetAccountUsers2.items.length; i++){
                                if(targetAccountUsers2.items[i].first_name === "service"){
                                    if(targetAccountUsers2.items[i].last_name === "user"){
                                        serviceUserId = targetAccountUsers2.items[i].id
                                    }
                                }
                            }
                            
                            if(serviceUserId === ""){
                                const message = "Couldn't get a service user in account " + targetAccountName
                                toastFail(message)
                            }
                            else{
                                AuthStore.createUserAPIToken(serviceUserId).finally(() => {
                                    download_deb_helper(serviceUserId, download_string, targetAccountName, targetAccountId, api_url)
                                })
                            }
                        })
                    })
                }
                else {
                    AuthStore.createUserAPIToken(serviceUserId).finally(() => {
                        download_deb_helper(serviceUserId, download_string, targetAccountName, targetAccountId,api_url)
                    })
                }     
            })
            .catch(console.error);
        }
        else{
            toastFail("Please select an account")
        }
    }

    function download_rpm_helper(serviceUserId: string, download_string: string, targetAccountName: string, targetAccountId: string, api_url: string) {
        toast.configure();
        toast.success("Preparing download", { autoClose: 30000 });
        AuthStore.loadUserAPIKey(serviceUserId).then(() => {
                if(AuthStore.serviceapikey === ""){
                    const message = "Couldn't get api key of service user in account " + targetAccountName
                    toastFail(message)
                }
                else{
                    toastSuccess("Downloading");
                    const serviceapikey = AuthStore.serviceapikey
                        var script_string = "curl -s https://packagecloud.io/install/repositories/ACD/armsendpointrpm/script.rpm.sh | bash\nyum update\n"
                        script_string = script_string + "curl -s https://packagecloud.io/install/repositories/ACD/armsendpointrpm/script.rpm.sh | bash\nyum update\n"
                        script_string = script_string + "[ -d /etc/phalanx_agent ] && sudo rm -rf /etc/phalanx_agent\n"
                        script_string = script_string + "mkdir /etc/phalanx_agent\n"
                        const settings_string = "RAND_PERIOD=0\nSERVER_URL=" + api_url + "/api/v2/\nAPI_TOKEN=" + serviceapikey + "\nACCOUNT_ID=" + targetAccountId;
                        const escaped_settings_string = settings_string.replace(/'/g, "'\"'\"'");
                        script_string = script_string + `echo '${escaped_settings_string}' > /etc/phalanx_agent/.phalanx_conf\n`;
                        script_string = script_string + "yum install -y phalanx-agent\n";
                        const filename = "ArmsEndpoint_for_" + targetAccountName + ".sh";
                        const blob = new Blob([script_string], { type: "text/plain" });
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.download = filename;
                        link.href = url;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        
                }
        })
    }
    function onDownloadRpm() {
        if(selectedAccount){
            const download_string = communityEdition ? 
                "https://storage.googleapis.com/winrand-msi/self_install/Current/Community/ArmsEndpoint.exe"
                : "https://storage.googleapis.com/winrand-msi/self_install/Current/ArmsEndpoint.exe"
            const api_url = process.env.REACT_APP_API_URL ?? ''
            const targetAccountId = selectedAccount.id
            const targetAccountName = selectedAccount.name
            const serviceUserEmail = "su@" + targetAccountId + ".com"
            const myuuid = makeid(40);
            const serviceUser = {first_name: "service",last_name: "user",email: serviceUserEmail,role:"admin",password: myuuid,account_id: targetAccountId}
            UsersStore.loadDataById(targetAccountId).then(() => {
                const targetAccountUsers = UsersStore.accountsById as UsersResponse
                let serviceUserId = ""
                for (let i=0; i<targetAccountUsers.items.length; i++){
                    if(targetAccountUsers.items[i].first_name === "service"){
                        if(targetAccountUsers.items[i].last_name === "user"){
                            serviceUserId = targetAccountUsers.items[i].id
                        }
                    }
                }
                if(serviceUserId === ""){
                    AuthStore.createServiceUser(serviceUser).then(() => {
                        UsersStore.loadDataById(targetAccountId).then(() => {
                            const targetAccountUsers2 = UsersStore.accountsById as UsersResponse
                            for (let i=0; i<targetAccountUsers2.items.length; i++){
                                if(targetAccountUsers2.items[i].first_name === "service"){
                                    if(targetAccountUsers2.items[i].last_name === "user"){
                                        serviceUserId = targetAccountUsers2.items[i].id
                                    }
                                }
                            }
                            
                            if(serviceUserId === ""){
                                const message = "Couldn't get a service user in account " + targetAccountName
                                toastFail(message)
                            }
                            else{
                                AuthStore.createUserAPIToken(serviceUserId).finally(() => {
                                    download_rpm_helper(serviceUserId, download_string, targetAccountName, targetAccountId, api_url)
                                })
                            }
                        })
                    })
                }
                else {
                    AuthStore.createUserAPIToken(serviceUserId).finally(() => {
                        download_rpm_helper(serviceUserId, download_string, targetAccountName, targetAccountId,api_url)
                    })
                }     
            })
            .catch(console.error);
        }
        else{
            toastFail("Please select an account")
        }
    }

    function onDownloadURI() {
        if(selectedAccount){
            const download_string = communityEdition ? 
                "https://storage.googleapis.com/winrand-msi/self_install/Current/Community/ArmsEndpoint.exe"
                : "https://storage.googleapis.com/winrand-msi/self_install/Current/ArmsEndpoint.exe"
            const api_url = process.env.REACT_APP_API_URL ?? ''
            const targetAccountId = selectedAccount.id
            const targetAccountName = selectedAccount.name
            const serviceUserEmail = "su@" + targetAccountId + ".com"
            const myuuid = makeid(40);
            const serviceUser = {first_name: "service",last_name: "user",email: serviceUserEmail,role:"admin",password: myuuid,account_id: targetAccountId}
            UsersStore.loadDataById(targetAccountId).then(() => {
                const targetAccountUsers = UsersStore.accountsById as UsersResponse
                let serviceUserId = ""
                for (let i=0; i<targetAccountUsers.items.length; i++){
                    if(targetAccountUsers.items[i].first_name === "service"){
                        if(targetAccountUsers.items[i].last_name === "user"){
                            serviceUserId = targetAccountUsers.items[i].id
                        }
                    }
                }
                if(serviceUserId === ""){
                    AuthStore.createServiceUser(serviceUser).then(() => {
                        UsersStore.loadDataById(targetAccountId).then(() => {
                            const targetAccountUsers2 = UsersStore.accountsById as UsersResponse
                            for (let i=0; i<targetAccountUsers2.items.length; i++){
                                if(targetAccountUsers2.items[i].first_name === "service"){
                                    if(targetAccountUsers2.items[i].last_name === "user"){
                                        serviceUserId = targetAccountUsers2.items[i].id
                                    }
                                }
                            }
                            
                            if(serviceUserId === ""){
                                const message = "Couldn't get a service user in account " + targetAccountName
                                toastFail(message)
                            }
                            else{
                                AuthStore.createUserAPIToken(serviceUserId).finally(() => {
                                    download_helper(serviceUserId, download_string, targetAccountName, targetAccountId, api_url)
                                })
                            }
                        })
                    })
                }
                else {
                    AuthStore.createUserAPIToken(serviceUserId).finally(() => {
                        download_helper(serviceUserId, download_string, targetAccountName, targetAccountId,api_url)
                    })
                }     
            })
            .catch(console.error);
        }
        else{
            toastFail("Please select an account")
        }
    }

    function onDownloadVer1_7_4() {
        toastSuccess("Preparing download");
        var download_string = "https://storage.googleapis.com/winrand-msi/vendor_install/Vantage/1.07.004/Winrand.exe";
        fetch(download_string, {mode: 'cors'}).then((response) => response.blob())
        .then((blobresp) => {
            var blob = new Blob([blobresp], {type: "octet/stream"});
            var mytext = Promise.resolve(blob.arrayBuffer())
            mytext.then((value) => {
                var zip = new JSZip();
                zip.file("Winrand.exe", value, {base64: true});
                zip.generateAsync({type:"blob"})
                .then(function(content) {
                    // Force down of the Zip file
                    var url = window.URL.createObjectURL(content);
                    var link = document.createElement("a");
                    link.download = "Winrand.zip";
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

            })
        })
        .catch(console.error);
    }

    function onDownloadVer1_7_5() {
        toastSuccess("Preparing download");
        var download_string = "https://storage.googleapis.com/winrand-msi/vendor_install/Vantage/1.07.005/Winrand.exe";
        fetch(download_string, {mode: 'cors'}).then((response) => response.blob())
        .then((blobresp) => {
            var blob = new Blob([blobresp], {type: "octet/stream"});
            var mytext = Promise.resolve(blob.arrayBuffer())
            mytext.then((value) => {
                var zip = new JSZip();
                zip.file("Winrand.exe", value, {base64: true});
                zip.generateAsync({type:"blob"})
                .then(function(content) {
                    // Force down of the Zip file
                    var url = window.URL.createObjectURL(content);
                    var link = document.createElement("a");
                    link.download = "Winrand.zip";
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

            })
        })
        .catch(console.error);
    }
    
    function onDownloadVer1_8_1() {
        toastSuccess("Preparing download");
        var download_string = "https://storage.googleapis.com/winrand-msi/vendor_install/Vantage/1.08.001/Winrand.exe";
        fetch(download_string, {mode: 'cors'}).then((response) => response.blob())
        .then((blobresp) => {      
            var blob = new Blob([blobresp], {type: "octet/stream"});
            var mytext = Promise.resolve(blob.arrayBuffer())
            mytext.then((value) => {
                var zip = new JSZip();
                zip.file("Winrand.exe", value, {base64: true});
                zip.generateAsync({type:"blob"})
                .then(function(content) {
                    // Force down of the Zip file
                    var url = window.URL.createObjectURL(content);
                    var link = document.createElement("a");
                    link.download = "Winrand.zip";
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

            })
        })
        .catch(console.error);
    }

    const selectOptions = accounts.map((account, index) => {
        return { value: index, label: account.name }
    })

    let selectedAccount: Account
    if (accounts)
        selectedAccount = accounts[selectedAccountIndex]

    return (
        <ScreenContainer className='Setup' disableGutters style={{maxWidth: '-webkit-fill-available'}}>
            <TitleContainer>
                <TabTitle>{DOWNLOAD.TITLE}</TabTitle>
                { AuthStore.accountName === "Vantage Mobility" ? null :
                    <StyledSelect
                        label="Account"
                        handleOnChange={accountsStore.setSelectedAccountIndex}
                        options={selectOptions}
                        value={selectedAccountIndex}/>
                }
            </TitleContainer>
            <Container disableGutters style={{margin: '0'}}>
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title={DOWNLOAD.WINDOWS_DESKTOPS.TITLE}>
                        <ReactMarkdown children={windowsvendormarkdown} />
                        {AuthStore.accountName === "Vantage Mobility" ? null :
                            <Button
                                onClick={onClickInstall(onDownloadURI)}
                                variant='contained'
                                style={{ marginLeft: '25px', padding: '11px' }}
                                startIcon={<FileDownloadIcon/> }>
                                {DOWNLOAD.DOWNLOAD_BUTTON}
                            </Button>
                        }
                        {AuthStore.accountName === "Vantage Mobility" ? <h3>Versions</h3> : null }
                        {AuthStore.accountName === "Vantage Mobility" ? <ul><li>Version 1.07.004 : Feature update - includes a minimal sysmon config to limit sysmon intrusiveness</li><li>Version 1.07.005 : Fix - restricts sending up duplicate alerts</li><li>Version 1.08.001 : Feature update - includes a balanced sysmon config (omits detection of registry changes)</li></ul> : null }
                        {AuthStore.accountName !== "Vantage Mobility" ? null :
                            <Button
                                onClick={onClickInstall(onDownloadVer1_7_4)}
                                variant='contained'
                                style={{ marginLeft: '25px', padding: '11px' }}
                                startIcon={<FileDownloadIcon/> }>
                                Version 1.07.004
                            </Button>
                        }
                        {AuthStore.accountName !== "Vantage Mobility" ? null :
                            <Button
                                onClick={onClickInstall(onDownloadVer1_7_5)}
                                variant='contained'
                                style={{ marginLeft: '25px', padding: '11px' }}
                                startIcon={<FileDownloadIcon/> }>
                                Version 1.07.005
                            </Button>
                        }
                        {AuthStore.accountName !== "Vantage Mobility" ? null :
                            <Button
                                onClick={onClickInstall(onDownloadVer1_8_1)}
                                variant='contained'
                                style={{ marginLeft: '25px', padding: '11px' }}
                                startIcon={<FileDownloadIcon/> }>
                                Version 1.08.001
                            </Button>
                        }   
                    </Tab>
                    <Tab eventKey="linux" title={"Linux"}>
                        <ReactMarkdown children={linuxvendormarkdown} />
                        <Button
                                onClick={onClickInstall(onDownloadDeb)}
                                variant='contained'
                                style={{ marginLeft: '25px', padding: '11px' }}
                                startIcon={<FileDownloadIcon/> }>
                                {"DEB Installer"}
                            </Button>
                            <Button
                                onClick={onClickInstall(onDownloadRpm)}
                                variant='contained'
                                style={{ marginLeft: '25px', padding: '11px' }}
                                startIcon={<FileDownloadIcon/> }>
                                {"RPM Installer"}
                            </Button>
                        </Tab>
                </Tabs>
            </Container>

            <StyledDialog
                open={openAgreement}
                onClose={onCloseAgreement}
                title='ARMS CYBER SOFTWARE LICENSE AGREEMENT'
                content={
                    <>
                        <ReactMarkdown children={licenseAgreementMarkdown} />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={readAgreement} onChange={onClickReadAgreement}/>} label={'I have read and accept the terms and conditions'}></FormControlLabel>
                        </FormGroup>
                    </>
                }
                disablePrimary={!readAgreement}
                onClickPrimary={onClickAgree}
                primaryText='Download'/>
        </ScreenContainer>
    )
}
