import { Button, styled } from "@mui/material"
import { useState } from "react"

interface EllipsisCellProps {
    text: string
}

export default function EllipsisCell({ text, ...props } : EllipsisCellProps) {
    const [ expanded, setExpanded ] = useState<boolean>(false)
    
    function handleToggle() {
        setExpanded((previousState => !previousState))
    }
    
    if (text === undefined || text === null)
        return <></>

    return (
        <div {...props}>
            {text.length <= 100 ? (
                <span>{text}</span>
            ) : (
                <>
                    { expanded && text.length > 100 ? (
                        <span>{text}</span>
                    ) : ( 
                        <>
                            <span>{text.substring(0, 100)}</span>
                            <span className="ellipsis">...</span>
                        </>
                    )}
                    <FullDescriptionButton onClick={handleToggle}>
                        {expanded ? 'Hide full description' : 'See full description'}
                    </FullDescriptionButton>
                </>
            )}
        </div>
    )
}

const FullDescriptionButton = styled(Button)({
    textTransform: 'none',
    padding: 0,
    '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'inherit'
    }
})
