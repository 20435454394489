import { createContext, useContext, useState } from "react"
import { makeAuthenticatedRequest } from "utils/API"
import { DeviceDetails, DeviceResponse } from "./DeviceDetailStore"
import { Device } from "./DevicesStore"
import { toastSuccess } from "utils/Toast"

interface DeviceDetailStore {
    data: DeviceDetails
    loadData: (deviceId: string) => Promise<void>
    assignPolicy: (policyId: string) => Promise<void>
    assignRansomWareProtection: (value: boolean) => Promise<void>
}

const DeviceDetailContext = createContext<DeviceDetailStore | undefined>(undefined)

export function useDeviceDetailStoreContext() {
    const stores = useContext(DeviceDetailContext)
    if (stores === undefined)
        throw new Error('useDeviceDetailStoreContext must be use wrapped around a DeviceDetailProvider')
    return stores
}

export default function DeviceDetailProvider({ children }: {children: any}) {
    const [data, setData] = useState<DeviceDetails>({} as DeviceDetails)

    async function loadData(deviceId: string) {
        setData(prevData => ({ ...prevData, id: deviceId }))
        return makeAuthenticatedRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v2/devices/${deviceId}`,
            options: { method: 'GET' }
        })
        .then(onLoadDataSuccess)
        .catch(onApiFailure)
    }

    function onLoadDataSuccess(retDevice: Device) {
        setData(prevData => ({
            ...prevData,
            ip_address: retDevice?.ip_address,
            account_id: retDevice?.account_id,
            account_name: retDevice?.account_name,
            user_id: retDevice?.user_id,
            hostname: retDevice?.hostname,
            entropy_score: retDevice?.entropy_score,
            last_heartbeat: retDevice?.last_heartbeat,
            windows_device_configuration_id: retDevice?.windows_device_configuration_id,
            type: retDevice?.type,
            agent_version: retDevice?.agent_version,
            ransomware_protection_restore_from_backup: retDevice?.ransomware_protection_restore_from_backup
        }))
    }

    async function assignPolicy(policyId: string) {
        let url = `/api/v2/accounts/${data.account_id}/devices/${data.id}/windows_device_configurations?windows_device_configuration_id=${policyId}`
        if (!data.account_id)
            url = `/api/v1/users/${data.user_id}/devices/${data.id}/windows_device_configurations?windows_device_configuration_id=${policyId}`
        return makeAuthenticatedRequest({
            url,
            options: { method: 'PATCH' },
        })
        .then(() => onAssignPolicySuccess(policyId))
        .catch(onApiFailure);
    }

    function onAssignPolicySuccess(policyId: string) {
        setData(prevData => ({ ...prevData, windows_device_configuration_id: policyId }))
        toastSuccess('Policy assigned to device!');
    }
    
    async function assignRansomWareProtection(value: boolean) {
        let url = `/api/v2/accounts/${data.account_id}/devices`
        if (!data.account_id)
            url = `/api/v1/users/${data.user_id}/devices`

        return makeAuthenticatedRequest({
            url,
            options: { method: 'PUT' },
            data: {
                ...data,
                ransomware_protection_restore_from_backup: value
            }
        })
        .then(onAssignRansomwareProtectionSuccess)
        .catch(onApiFailure)
    }

    function onAssignRansomwareProtectionSuccess(response: DeviceResponse) {
        setData(prevData => ({
            ...prevData, 
            ransomware_protection_restore_from_backup: response.ransomware_protection_restore_from_backup
        }))
    }

    function onApiFailure(e: Error) {
        throw e
    }

    return (
        <DeviceDetailContext.Provider value={{
            data,
            loadData,
            assignPolicy,
            assignRansomWareProtection
        }}>
            { children }
        </DeviceDetailContext.Provider>
    )
}
