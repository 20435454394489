import React, { useEffect, useState } from 'react';
import './Endpoints.scss';
import { DeviceKey, useDevicesStoreContext } from 'stores/DevicesProvider';
import EndpointsTable from './EndpointsTable';
import { Order } from 'stores/AlertsStore';
import { Container } from '@mui/material';
import StyledSelect from 'components/StyledSelect';
import { useAccountsStoreContext } from 'stores/AccountsProvider';
import { ScreenContainer } from '../settings/Settings';
import styled from 'styled-components';

const DEFAULT_ROWS_PER_PAGE = 10
var selectedAccountId = ""

export default function Endpoints() {
    const [desktopsRowsPerPage, setDesktopsRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE)
    const [serversRowsPerPage, setServersRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE)

    const devicesStore = useDevicesStoreContext()
    const accountsStore = useAccountsStoreContext()

    const { loading, serversCount, desktopsCount, desktopsOrder, desktopsOrderBy, serversOrder, serversOrderBy } = devicesStore
    const { selectedAccountIndex, accounts } = accountsStore

    const totalCount = serversCount  + desktopsCount

    useEffect(() => {
        accountsStore.loadAccounts()
    }, [])

    // Load desktop endpoints when something changes
    useEffect(() => {
        const accountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
        selectedAccountId = accountId
        devicesStore.loadDesktopData(desktopsRowsPerPage, "", accountId)
    }, [desktopsRowsPerPage, desktopsOrder, desktopsOrderBy, selectedAccountIndex])

    // Load server endpoints when something changes
    useEffect(() => {
        const accountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
        selectedAccountId = accountId
        devicesStore.loadServerData(serversRowsPerPage, "", accountId)
    }, [serversRowsPerPage, serversOrder, serversOrderBy, selectedAccountIndex])

    function handleDesktopsPageChange(newPage: number, oldPage: number) {
        if ((newPage - oldPage) === 1)
            devicesStore.loadDesktopData(desktopsRowsPerPage, devicesStore.desktopsCursorAfter, selectedAccountId)
        else if ((newPage - oldPage) > 1)
            devicesStore.loadDesktopData(desktopsRowsPerPage, devicesStore.desktopsCursorLast, selectedAccountId)
        else if (newPage === 0)
            devicesStore.loadDesktopData(desktopsRowsPerPage, "", selectedAccountId)
        else if ((newPage - oldPage) === -1)
            devicesStore.loadDesktopData(desktopsRowsPerPage, devicesStore.desktopsCursorBefore, selectedAccountId)
    }

    function handleServersPageChange(newPage: number, oldPage: number) {
        if ((newPage - oldPage) === 1)
            devicesStore.loadServerData(serversRowsPerPage, devicesStore.serversCursorAfter, selectedAccountId)
        else if ((newPage - oldPage) > 1)
            devicesStore.loadServerData(serversRowsPerPage, devicesStore.serversCursorLast, selectedAccountId)
        else if (newPage === 0)
            devicesStore.loadServerData(serversRowsPerPage, "", selectedAccountId)
        else if ((newPage - oldPage) === -1)
            devicesStore.loadServerData(serversRowsPerPage, devicesStore.serversCursorBefore, selectedAccountId)
    }

    function handleDesktopsSort(order: Order, orderBy: string) {
        const {desktopsOrderBy, setDesktopsOrder, setDesktopsOrderBy } = devicesStore
        handleSort(order, orderBy as DeviceKey, desktopsOrderBy, setDesktopsOrder, setDesktopsOrderBy)
    }

    function handleServersSort(order: Order, orderBy: string) {
        const { serversOrderBy, setServersOrder, setServersOrderBy } = devicesStore
        handleSort(order, orderBy as DeviceKey, serversOrderBy, setServersOrder, setServersOrderBy)
    }

    function handleSort(order: Order, orderBy: string, currentOrderBy: DeviceKey, setOrder: (order: Order) => void, setOrderBy: (orderBy: DeviceKey) => void) {
        if (orderBy === currentOrderBy)
            setOrder(order)
        else {
            setOrder('asc')
            setOrderBy(orderBy as DeviceKey)
        }
    }

    const options = accounts.map((account, index) => {
        return { value: index, label: account.name }
    })
    const selectOptions = [{value: -1, label: 'All accounts'}, ...options]
    const accountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id

    return (
        <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}} className="Endpoints">
            <div className="title-container">
                <div className="title-info-container">
                    <h1 className="title-endpoints">Endpoints</h1>
                    <span className="total-endpoints">Total Endpoints Connected: {totalCount}</span>
                </div>
                <StyledSelect
                    label="Account"
                    handleOnChange={accountsStore.setSelectedAccountIndex}
                    options={selectOptions}
                    value={selectedAccountIndex}/>
            </div>

            <Container disableGutters style={{maxWidth: '-webkit-fill-available', marginBottom: '30px'}}>
                <TableTitle>Desktops</TableTitle>
                <EndpointsTable
                    devices={devicesStore.desktops}
                    loading={loading}
                    onChangePage={handleDesktopsPageChange}
                    onChangeRowsPerPage={setDesktopsRowsPerPage}
                    onSort={handleDesktopsSort}
                    rowsPerPage={desktopsRowsPerPage}
                    order={desktopsOrder}
                    orderBy={desktopsOrderBy}
                    accountId={accountId}
                    cursorBefore={devicesStore.desktopsCursorBefore}
                    cursorCurrent={devicesStore.desktopsCursorCurrent}
                    devicesCount={devicesStore.desktopsCount}/>
            </Container>
            <Container disableGutters style={{maxWidth: '-webkit-fill-available', marginBottom: '30px'}}>
                <TableTitle>Servers</TableTitle>
                <EndpointsTable 
                    devices={devicesStore.servers}
                    loading={loading}
                    onChangePage={handleServersPageChange}
                    onChangeRowsPerPage={setServersRowsPerPage}
                    onSort={handleServersSort}
                    rowsPerPage={serversRowsPerPage}
                    order={serversOrder}
                    orderBy={serversOrderBy}
                    accountId={accountId}
                    cursorBefore={devicesStore.serversCursorBefore}
                    cursorCurrent={devicesStore.serversCursorCurrent}
                    devicesCount={devicesStore.serversCount}/>
            </Container>
        </ScreenContainer>
    )
}

export const TableTitle = styled('h1')`
    color: white;
    font-size: 24px;
    font-weight: bolder;
    margin-left: 0px;
    margin-bottom: 10px;
`