import React from 'react';

import DropDownButton from 'components/dropdown/DropDownButton';

import './User.scss'
import { useStoresContext } from 'stores/StoresProvider';

export default function User() {
    const { authStore: AuthStore } = useStoresContext()
    const { user } = AuthStore

    const navigateToProfile = (event: any) => {
        event.preventDefault();
        window.location.href = '/profile'
    }

    const logOut = (event: any) => {
        event.preventDefault();
        AuthStore.logout();
    }

    return (
        <li className="nav-item dropdown user-menu">
            <DropDownButton
                size="md"
                iconCls="fas fa-user"
                containerTag="ul"
            >
                <li className="user-header bg-danger">
                    <img
                        src={'/img/default-profile.png'}
                        className="img-circle elevation-2"
                        alt="User"
                    />

                    <p>{user ? user.email : ''}</p>
                </li>
                <li className="user-footer">
                    <button
                        type="button"
                        className="btn-default"
                        onClick={navigateToProfile}
                        style={{color: 'white'}}
                    >
                        Profile
                    </button>
                    <button
                        type="button"
                        className="btn-logout"
                        onClick={logOut}
                    >
                        Logout
                    </button>
                </li>
            </DropDownButton>
        </li>
    );
};

