import { ButtonBase, styled } from "@mui/material";
import { useNavigate } from "react-router";

interface ButtonCardProps {
    count: string | number
    title: any
    icon?: any
    onClick: () => void
    to: string
}

export default function ButtonCard({count, title, icon, onClick, to}: ButtonCardProps) {
    const navigate = useNavigate()

    function handleClickAndNavigate() {
        onClick()
        navigate(to)
    }

    return (
        <StyledButton onClick={handleClickAndNavigate}>
            <div className={`small-box small-box-custom-styling`}>
                <div className="icon-styling">
                    { icon }
                </div>
                <p>{count}</p>
                <p>{title}</p>
            </div>
        </StyledButton>
    )
}

const StyledButton = styled(ButtonBase)(({ theme }) => ({
    height: '100%',
    width: '100%',
    '& .small-box': {
        height: '100%',
        width: '100%',
        border: '1px solid',
        borderColor: '#222227'
    },
    '&:hover': {
        '& .small-box': {
          borderColor: theme.palette.primary.dark,
          backgroundColor: 'rgba(128, 128, 128, 0.1)'
        },
    }
}))
