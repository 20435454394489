import React from 'react';
import packageInfo from '../../../package.json';

import './Footer.scss';

const Footer = () => (
    <footer className="main-footer">
        <strong>
            <span>Copyright &copy; 2024 </span>
            <span style={{marginLeft:20}}>Arms Cyber</span>
        </strong>
        <div className="float-right d-none d-sm-inline-block">
            <b>Version</b>
            <span>&nbsp;{packageInfo.version}</span>
        </div>
    </footer>
);

export default Footer;
