import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { toastFail, toastSuccess } from 'utils/Toast';
import { User } from 'stores/AuthStore';

import Card from 'components/Card';
import ModalUsers from 'components/ModelUsers';

import './EditAccountModal.scss';
import moment from 'moment';

const FieldsetColumn = styled('fieldset')`
    input {
        margin-bottom: 20px;
    }
`;

type EditUserModalProps = {
    AccountsStore?: any;
    isEditing?: User;
    AuthStore?: any;
};

class EditUserModal extends React.Component<EditUserModalProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            account: '',
            name: '',
        };
    }


    componentDidMount() {
        this.setState({ account: '', name: ''})
        const { AccountsStore } = this.props;
        const { selectedAccount } = AccountsStore;

        AccountsStore.getAllAcounts();

        if (selectedAccount.id) {
            this.setState({ account: selectedAccount.id ,name: selectedAccount.name })
        }
    }

    onSave = (e: any) => {
        const { AccountsStore, AuthStore } = this.props;
        const { selectedAccount } = AccountsStore;
        const { form } = e.target

        // these will be HTML <input /> elements, not the raw values
        const { name } = form;

        e.preventDefault();

        const saveFn = selectedAccount.id ? AccountsStore.update : AccountsStore.create;
        console.log(selectedAccount?.id)
        var data:any = {
            id: selectedAccount?.id,
            name: name.value,
            subscription_months: 12,
            date_last_subscription: moment().format('YYYY-MM-DD'),
            users_limit: 10000,
            devices_limit: 10000,
        }

        if(!AuthStore.isSupervisor) {
            data = {
                id: selectedAccount?.id,
                parent_account_id: AuthStore.user.account_id,
                name: name.value,
                subscription_months: 12,
                date_last_subscription: moment().format('YYYY-MM-DD'),
                users_limit: 10000,
                devices_limit: 10000,
            }
        }
        console.error(data)
        saveFn(data)
            .then(() => {
                toastSuccess('Account saved!');
                this.onCancel();
            })
            .catch((e:any) => {
                toastFail(e);
            });
    }

    onCancel = () => {
        const { AccountsStore } = this.props;
        AccountsStore.clearSelectedAccount();
        this.setState({ account: '', name: '' })
    }

    onAssignAccountId = (id:any) => {
        this.setState({account: id})
    }

    onAssignRole = (role:any) => {
        this.setState({role: role})
    }
    
    render() {
        const { AccountsStore } = this.props
        const { selectedAccount } = AccountsStore;

        const {
            name
        } = selectedAccount || { name: ''};

        

        return (
            <ModalUsers title={selectedAccount.id ? 'Edit Account' : 'Create Account'} onClose={this.onCancel}>
                <Card>
                    <form style={{ width: '300px' }}>
                        <FieldsetColumn>
                            <label htmlFor="name">Account Name</label>
                            <input
                                name="name"
                                id="name"
                                type="text"
                                placeholder="Company/Department"
                                className="form-control"
                                defaultValue={name}
                                style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                            />




                            
                        </FieldsetColumn>

                        <button className="btn btn-primary" type="button" onClick={this.onSave}>Save</button>
                        <button className="btn btn-secondary" type="button" onClick={this.onCancel}>Close</button>
                    </form>
                </Card>
            </ModalUsers>
        );
    }
}

export default inject('AccountsStore', 'AuthStore')(observer(EditUserModal));