import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { toastFail } from "utils/Toast";
import loginLogo from "./login-logo.png"
import { useStoresContext } from "stores/StoresProvider";

import "./Login.scss";
import { useSingleSignOnStoreContext } from "stores/SingleSignOnProvider";
import SSOGoogleButton from "components/sso/SSOGoogleButton";

export default function Login() {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    
    const ssoEnvVar = process.env.REACT_APP_DISABLE_SSO_BUTTON
    const disableSSOGoogleButton = ssoEnvVar && ssoEnvVar.toLowerCase() === 'true'

    const singleSignOnStore = useSingleSignOnStoreContext()
    const { authStore: AuthStore } = useStoresContext()

    function loginFail() {
        toastFail("Invalid username or password")
    }

    function loginSuccess() {
        window.location.href = "/"
    }

    function attemptLogin(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault()
        AuthStore.login({ username, password }).then(loginSuccess).catch(loginFail)
    }

    function attemptLoginSSO(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault()
        singleSignOnStore.signInPopUp()
        .then(credential => {
            if (credential)
                return AuthStore.loginSSO(credential)    
            return Promise.reject()
        })
        .then(loginSuccess)
        .catch(loginFail)
    }

    function updateUsername(e: React.ChangeEvent<HTMLInputElement>) {
        setUsername(e.target.value)
    }

    function updatePassword(e: React.ChangeEvent<HTMLInputElement>) {
        setPassword(e.target.value)
    }

    if (AuthStore.isAuthenticated)
        return <Navigate replace to="/" />

    return (
        <section id="Login">
            <form>
                <img className="logo" src={loginLogo}  alt="Logo" />
                <h1 className="login-welcome">Welcome</h1>

                <fieldset>
                    <div className="username">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
                        </svg>
                        <input
                            className="user-input"
                            type="email"
                            id="login-email"
                            placeholder="email"
                            onChange={updateUsername}
                        />
                    </div>
                    <div className="password">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z" />
                        </svg>
                        <input
                            className="pass-input"
                            type="password"
                            id="login-password"
                            placeholder="password"
                            onChange={updatePassword}
                        />
                    </div>
                </fieldset>

                <button type="button" className="login-button" onClick={attemptLogin}>Login</button>
                <a href="/resetpassword">Reset Password</a>

                { !disableSSOGoogleButton && 
                    <SSOGoogleButton onClick={attemptLoginSSO} style={{placeSelf: 'center'}} />
                }    
            </form>
        </section>
    )
}
