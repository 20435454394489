import React from "react";
import '../../../components/Modal.scss';
import {toastFail, toastSuccess} from "../../../utils/Toast";

class DeleteDeviceModal extends React.Component<{ row: any, onConfirm: any }, { isOpen: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {isOpen: false};
    }
    toggleModal = () => {
        this.setState(prevState => {
            this.setState({isOpen: !prevState.isOpen});
        });
    }
    onConfirm = () => {
        this.props.onConfirm(this.props.row).then(() => {
            toastSuccess('Device deleted!');
            this.toggleModal();
        })
            .catch((e: any) => {
                toastFail(e);
            });

    }

    render() {
        return (
            <div className="text-center">
                <i
                    className="fas fa-trash-alt"
                    onClick={this.toggleModal}
                    style={{cursor: 'pointer', padding: 10}}
                />
                {this.state.isOpen &&
                    <div tabIndex={-1} role="dialog" id="Dialogue" className="dialogue"
                         style={{position: 'fixed', top: '10%', zIndex: 10, right: 0}}>
                        <div className="modal-content">
                            <div className="modal-header" style={{borderBottom: 'none'}}>
                                <h5 className="modal-title" style={{whiteSpace: 'initial' , marginTop: -5, textAlign: 'center'}}>Are you sure
                                    you want to delete the device {this.props.row.hostname}?</h5>
                                <button type="button" className="close" aria-label="Close" onClick={this.toggleModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <button className="btn btn-primary" type="button" onClick={this.onConfirm}>Delete
                                </button>
                                <button className="btn btn-secondary" type="button" onClick={this.toggleModal}>Cancel
                                </button>

                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
}

export default DeleteDeviceModal
