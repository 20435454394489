import React, {useEffect, useRef, useState} from 'react';

type DropDownMenuProps = {
    size: 'sm' | 'md' | 'lg';
    containerTag?: string;
    children: any[];
};

const DropDownMenu = ({size = 'md', children = [], containerTag = 'div'}: DropDownMenuProps) => {
    const dropdownMenuRef = useRef<any>(null);

    const [styles, setStyles] = useState({left: 'inherit', right: `0px`});
    const [classes, setClasses] = useState(
        `dropdown-menu dropdown-menu-right dropdown-menu-${size} show`
    );

    useEffect(() => {
        setClasses(
            `dropdown-menu dropdown-menu-right dropdown-menu-${size} show`
        );
        const dropdownMenuElement = dropdownMenuRef.current;
        if (dropdownMenuElement) {
            const windowWidth = document.getElementById('root')?.offsetWidth;
            const offsetLeft = dropdownMenuElement.getBoundingClientRect().left;
            const {offsetWidth} = dropdownMenuElement;
            let visiblePart;

            if(windowWidth) {
                visiblePart = windowWidth - offsetLeft;
            }


            if (offsetLeft < 0) {
                setStyles({
                    left: 'inherit',
                    right: `${offsetLeft - 5}px`
                });
            } else if(visiblePart) {
                if(visiblePart < offsetWidth) {
                    setStyles({left: 'inherit', right: `0px`});
                }
            }
        }
    }, [size]);

    if (containerTag === 'ul') {
        return (
            <ul
                ref={dropdownMenuRef}
                className={classes}
                style={{...styles, padding: 0}}
            >
                {children}
            </ul>
        );
    }

    return (
        <div
            ref={dropdownMenuRef}
            className={classes}
            style={{...styles, padding: 0}}
        >
            {children}
        </div>
    );
};

export default DropDownMenu;
