import PROPERTIES from "properties";
import { ScreenContainer, TabTitle } from "../settings/Settings";
import { Category, LoadingOptions, Severity, useAlertsStoreContext } from "stores/AlertsProvider";
import { Order } from "stores/AlertsStore";
import { Button, Container } from "@mui/material";
import { AlertKey, AlertsTable } from "../home/AlertsTable";
import { useEffect, useState } from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TitleContainer } from "../home/Home";
import { useAccountsStoreContext } from "stores/AccountsProvider";
import StyledSelect from "components/StyledSelect";
import FiltersBar, { Filters } from "components/FiltersBar";

const { EVENTS } = PROPERTIES
export const DEFAULT_ROWS_PER_PAGE = 10

export default function Events() {
    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE)

    const alertsStore = useAlertsStoreContext()
    const accountsStore = useAccountsStoreContext()

    const { loadingOptions } = alertsStore
    const { severitiesFilter, categoriesFilter, mitigationFilter, order, sortBy } = loadingOptions
    const { accounts, selectedAccountIndex } = accountsStore

    const filters: Filters = {
        severity: severitiesFilter,
        category: categoriesFilter,
        mitigation: mitigationFilter
    }

    useEffect(() => {
        accountsStore.loadAccounts()
    }, [])

    // Call for alerts data from the start whenever the rowsPerPage changes
    useEffect(() => {
        alertsStore.setLoadOptions({
            count: rowsPerPage,
            cursor: ''
        })
    }, [rowsPerPage])

    useEffect(() => {
        alertsStore.loadAlerts()
    }, [selectedAccountIndex, accounts, loadingOptions])

    function handleFilterChange(filters: Filters) {
        const options: Partial<LoadingOptions> = {
            severitiesFilter: filters.severity,
            categoriesFilter: filters.category,
            mitigationFilter: filters.mitigation,
            cursor: ''
        }
        alertsStore.setLoadOptions(options)
    }

    function handleClickOnTag(tag: Severity | Category) {
        if (isSeverity(tag))
            alertsStore.setLoadOptions({ severitiesFilter: [tag as Severity] })
        else
            alertsStore.setLoadOptions({ categoriesFilter: [tag as Category] })
    }

    function handlePageChange(newPage: number, oldPage: number) {
        if ((newPage - oldPage) === 1)
            alertsStore.setLoadOptions({ cursor: alertsStore.cursorAfter })
        else if ((newPage - oldPage) > 1)
            alertsStore.setLoadOptions({ cursor: alertsStore.cursorLast })
        else if (newPage === 0)
            alertsStore.setLoadOptions({ cursor: '' })
        else if ((newPage - oldPage) === -1)
            alertsStore.setLoadOptions({ cursor: alertsStore.cursorBefore })
    }

    function handleSort(order: Order, orderBy: string) {
        alertsStore.setLoadOptions({
            order,
            sortBy: orderBy as AlertKey
        })
    }

    const options = accounts.map((account, index) => {
        return { value: index, label: account.name }
    })
    const selectOptions = [{value: -1, label: 'All accounts'}, ...options]

    return (
        <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}}>
            <Container disableGutters style={{marginLeft: 0, maxWidth: 'inherit'}}>
                <TitleContainer style={{marginRight: 0}}>
                    <TabTitle>{EVENTS.TITLE}</TabTitle>
                    <StyledSelect
                        label="Account"
                        handleOnChange={accountsStore.setSelectedAccountIndex}
                        options={selectOptions}
                        value={selectedAccountIndex}/>
                </TitleContainer>
                <Container 
                    disableGutters
                    style={{
                        maxWidth: 'inherit',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px'
                    }}
                >
                    <FiltersBar
                        filters={filters}
                        onFiltersChange={handleFilterChange}/>
                    <Button startIcon={<FileDownloadIcon />} onClick={alertsStore.downloadAlertsCSV}>
                        {EVENTS.DOWNLOAD_CSV}
                    </Button>
                </Container> 

                <AlertsTable
                    alerts={alertsStore.alerts}
                    totalAlertsCount={alertsStore.total}
                    loading={alertsStore.loading}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setRowsPerPage}
                    onSort={handleSort}
                    rowsPerPage={rowsPerPage}   
                    order={order}
                    orderBy={sortBy}
                    severityFilter={severitiesFilter}
                    categoryFilter={categoriesFilter}
                    onClickOnTag={handleClickOnTag}/>
            </Container>
        </ScreenContainer>
    )
}

export function isSeverity(tag: Severity | Category) {
    return tag === 'ransomware' || tag === 'siem' || tag === 'ids';
}