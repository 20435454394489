import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface StyledSelectProps {
  label?: string
  handleOnChange: (value: any) => void
  options: { value: any, label?: string }[]
  value?: any
  disabled? : boolean
  className?: string
}

export default function StyledSelect({ label, handleOnChange, options, value, disabled, className }: StyledSelectProps) {
  const handleChange = (event: SelectChangeEvent) => {
    handleOnChange(event.target.value)
  }

  return (
    <Box className={className} sx={defaultBoxStyle} >
      <FormControl fullWidth>
        <InputLabel id="select-label">{label ? label : ''}</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={value}
          label={label ? label : ''}
          onChange={handleChange}
          disabled={disabled}
        >
          { options.map((option, index) => {
            return <MenuItem value={option.value} key={index}>{option.label ? option.label : option.value}</MenuItem>
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

const defaultBoxStyle = { 
  minWidth: 160,
  marginTop: '10px'
}
