import { createContext, useContext, useState } from "react"
import { makeAuthenticatedDownloadRequest, makeAuthenticatedRequest } from "utils/API"
import { Alert, Metadata, Order } from "./AlertsStore"
import { AlertKey } from "views/main/home/AlertsTable"
import moment from "moment"
import { pastYearMonthsArray } from "utils/Date"
import { useAccountsStoreContext } from "./AccountsProvider"

export type Severity = 'ransomware' | 'siem' | 'ids'
export type Category = 'hardening' | 'detection' | 'mitigation'
export type Mitigation = 'blocked' | 'unblocked'
export type LoadingOptions = {
    severitiesFilter: Severity[]
    categoriesFilter: Category[]
    mitigationFilter: Mitigation[]
    sortBy: AlertKey
    order: Order
    count: number
    cursor: string
}

interface AlertsStore {
    loading: boolean
    alerts: Alert[]
    total: number
    topAttacked: AttackedDevice[]
    topRansomwareAttacked: AttackedDevice[]
    alertsPerMonth: { labels: string[], values: number[] }
    idsAlertsPerDay: ChartData
    ransomwareAlertsPerDay: ChartData
    cursorBefore: string
    cursorAfter: string
    cursorLast: string
    loadingOptions: LoadingOptions
    selectedEndpointAlerts: Alert[]
    selectedEndpointTotal: number
    selectedEndpointCursorBefore: string
    selectedEndpointCursorAfter: string
    selectedEndpointCursorLast: string
    selectedEndpointLoadingOptions: LoadingOptions
    loadAlerts: () => void
    loadSelectedEndpointAlerts: (deviceId: string) => void
    loadTopIDSAttacked: (accountId: string) => void
    loadTopRansomwareAttacked: (accountId: string) => void
    loadAlertsPerMonth: (accountId: string, role: string) => void
    loadAlertsPerDay: (accountId: string, role: string) => void
    downloadAlertsCSV: () => void
    downloadDeviceAlertsCSV: (deviceId: string, hostName: string) => void
    setLoadOptions: (options: Partial<LoadingOptions>) => void
    setSelectedEndpointLoadOptions: (options: Partial<LoadingOptions>) => void
    getAlertsCount: (severity: Severity, mitigated?: boolean, accountId?: string) => Promise<number>
}

type ChartData = {
    labels: string[]
    values: number[]
}

const empytChartData = {
    labels: [],
    values: []
} as ChartData

const AlertsContext = createContext<AlertsStore | undefined>(undefined)

export function useAlertsStoreContext() {
    const stores = useContext(AlertsContext)
    if (stores === undefined)
        throw new Error('useAlertsStoreContext must be use wrapped around a AlertsProvider')
    return stores
}

const DEFAULT_ROWS_PER_PAGE = 10
const INITIAL_LOADING_OPTIONS: LoadingOptions = {
    severitiesFilter: ['ransomware'],
    categoriesFilter: ['detection'],
    mitigationFilter: [],
    order: 'desc',
    sortBy: 'timestamp',
    count: DEFAULT_ROWS_PER_PAGE,
    cursor: ''
}

export default function AlertsProvider({ children }: {children: any}) {
    const [alerts, setAlerts] = useState<Alert[]>([])
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [topIDSAttacked, setTopIDSAttacked] = useState<AttackedDevice[]>([])
    const [topRansomwareAttacked, setTopRansomwareAttacked] = useState<AttackedDevice[]>([])
    const [alertsPerMonth, setAlertsPerMonth] = useState({labels: [], values: []})
    const [idsAlertsPerDay, setIDSAlertsPerDay] = useState<ChartData>(empytChartData)
    const [ransomwareAlertsPerDay, setRansomwareAlertsPerDay] = useState<ChartData>(empytChartData)
    const [cursorBefore, setCursorBefore] = useState<string>('')
    const [cursorAfter, setCursorAfter] = useState<string>('')
    const [cursorLast, setCursorLast] = useState<string>('')
    const [loadingOptions, setLoadingOptions] = useState<LoadingOptions>(INITIAL_LOADING_OPTIONS)
    const [selectedEndpointAlerts, setSelectedEndpointAlerts] = useState<Alert[]>([])
    const [selectedEndpointTotal, setSelectedEndpointTotal] = useState<number>(0)
    const [selectedEndpointCursorBefore, setSelectedEndpointCursorBefore] = useState<string>('')
    const [selectedEndpointCursorAfter, setSelectedEndpointCursorAfter] = useState<string>('')
    const [selectedEndpointCursorLast, setSelectedEndpointCursorLast] = useState<string>('')
    const [selectedEndpointLoadingOptions, setSelectedEndpointLoadingOptions] = useState<LoadingOptions>(INITIAL_LOADING_OPTIONS)
    
    const accountsStore = useAccountsStoreContext()
    const { selectedAccountIndex, accounts } = accountsStore

    function translateSeverity(severity: Severity) {
        if (severity === "ransomware")
            return "critical"
        if (severity === "ids")
            return "error,alert,emergency,warning"
    
        // if it isn't 'ransomware', or 'ids', must be 'siem'
        return "notice,informational,debug"
    }

    // mitigationFilter should have only one element for this method to be called
    function translateMitigationFilter(mitigationFilter: Mitigation[]) {
        if (mitigationFilter[0] === 'blocked')
            return 'True'

        // Filter must be 'unblocked'
        return 'False'
    }

    async function loadAlerts() {
        setLoading(true)
        const { order, sortBy, severitiesFilter, categoriesFilter, mitigationFilter, count, cursor } = loadingOptions

        // Build url
        let url = `/api/v2/alerts`
        const selectedAccountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
        if (selectedAccountId && selectedAccountId !== '')
            url = `/api/v2/accounts/${selectedAccountId}/alerts`
        
        // Set query params
        const sort = `${order === 'desc' ? '-' : ''}${sortBy}`
        url = url.concat(`?sort=${sort}&severities=${severitiesFilter.map(translateSeverity).join(',')}&categories=${categoriesFilter.join(',')}&include_subaccounts=true`)
        if (count !== -1)
            url = url.concat(`&count=${count}&cursor=${cursor}`)
        if (mitigationFilter.length === 1)
            url = url.concat(`&mitigated=${translateMitigationFilter(mitigationFilter)}`)

        return makeAuthenticatedRequest({ url, options: {method: 'GET'}})
            .then(onLoadAlerts)
            .catch(onApiFailure)
    }

    function onLoadAlerts({items, total, metadata}: AlertsResponse) {
        setAlerts(items)
        setTotal(total)
        setCursors(metadata)
        setLoading(false)
    }

    function setCursors(metadata: Metadata) {
        if (metadata != null) {
            if (metadata.cursor_after != null)
                setCursorAfter(metadata.cursor_after)
            else
                setCursorAfter('')
            if (metadata.cursor_before != null)
                setCursorBefore(metadata.cursor_before)
            else
                setCursorBefore('')
            if (metadata.cursor_last != null)
                setCursorLast(metadata.cursor_last)
            else
                setCursorLast('')
        }
    }

    async function loadSelectedEndpointAlerts(deviceId: string) {
        setLoading(true)
        const { order, sortBy, severitiesFilter, categoriesFilter, mitigationFilter, count, cursor } = selectedEndpointLoadingOptions

        // Build url
        let url = `/api/v2/devices/${deviceId}/alerts`
        
        // Set query params
        const sort = `${order === 'desc' ? '-' : ''}${sortBy}`
        url = url.concat(`?sort=${sort}&severities=${severitiesFilter.map(translateSeverity).join(',')}&categories=${categoriesFilter.join(',')}&include_subaccounts=true`)
        if (count !== -1)
            url = url.concat(`&count=${count}&cursor=${cursor}`)
        if (mitigationFilter.length === 1)
            url = url.concat(`&mitigated=${translateMitigationFilter(mitigationFilter)}`)

        return makeAuthenticatedRequest({ url, options: {method: 'GET'}})
            .then(onLoadSelectedEndpointAlerts)
            .catch(onApiFailure)
    }

    function onLoadSelectedEndpointAlerts({items, total, metadata}: AlertsResponse) {
        setSelectedEndpointAlerts(items)
        setSelectedEndpointTotal(total)
        setSelectedEndpointCursors(metadata)
        setLoading(false)
    }

    function setSelectedEndpointCursors(metadata: Metadata) {
        if (metadata != null) {
            if (metadata.cursor_after != null)
                setSelectedEndpointCursorAfter(metadata.cursor_after)
            else
                setSelectedEndpointCursorAfter('')
            if (metadata.cursor_before != null)
                setCursorBefore(metadata.cursor_before)
            else
                setSelectedEndpointCursorBefore('')
            if (metadata.cursor_last != null)
                setCursorLast(metadata.cursor_last)
            else
                setSelectedEndpointCursorLast('')
        }
    }

    async function loadTopIDSAttacked(accountId: string) {
        const url = `/api/v2/accounts/${accountId}/devices/top-attacked?count=5&severities=${translateSeverity('ids')}&include_subaccounts=true`
        return (makeAuthenticatedRequest({ url }))
            .then(response => {
                return onLoadTopAttacked(response as TopAttackedResponse)
            })
            .catch(onApiFailure)
    }

    function onLoadTopAttacked(response: TopAttackedResponse) {
        const attackedDevices: AttackedDevice[] = response.items.map((item) => {
            return { id:item.device.id,hostname: item.device.hostname, totalAttacks: item.total_attacks}
        })
        const sortedDevices = attackedDevices.sort((a: AttackedDevice, b: AttackedDevice) => {
            return b.totalAttacks - a.totalAttacks
        })
        if(sortedDevices.length === 0){
            setTopIDSAttacked([])
        }else if(sortedDevices[0].totalAttacks === 0){
            setTopIDSAttacked([])
        }else if(sortedDevices.length === 1){
            setTopIDSAttacked(sortedDevices.slice(0, 1))
        }else if(sortedDevices[1].totalAttacks === 0){
            setTopIDSAttacked(sortedDevices.slice(0, 1))
        }else if(sortedDevices.length === 2){
            setTopIDSAttacked(sortedDevices.slice(0, 2))
        }else if(sortedDevices[2].totalAttacks === 0){
            setTopIDSAttacked(sortedDevices.slice(0, 2))
        }else if(sortedDevices.length === 3){
            setTopIDSAttacked(sortedDevices.slice(0, 3))
        }else if(sortedDevices[3].totalAttacks === 0){
            setTopIDSAttacked(sortedDevices.slice(0, 3))
        }else{
            setTopIDSAttacked(sortedDevices.slice(0, 4))
        }
    }

    async function loadTopRansomwareAttacked(accountId: string) {
        const url = `/api/v2/accounts/${accountId}/devices/top-attacked?count=5&severities=${translateSeverity('ransomware')}&include_subaccounts=true`
        return (makeAuthenticatedRequest({ url }))
            .then(response => {
                return onLoadTopRansomwareAttacked(response as TopAttackedResponse)
            })
            .catch(onApiFailure)
    }

    function onLoadTopRansomwareAttacked(response: TopAttackedResponse) {
        const attackedDevices: AttackedDevice[] = response.items.map((item) => {
            return { id: item.device.id, hostname: item.device.hostname, totalAttacks: item.total_attacks}
        })
        const sortedDevices = attackedDevices.sort((a: AttackedDevice, b: AttackedDevice) => {
            return b.totalAttacks - a.totalAttacks
        })
        if(sortedDevices.length === 0){
            setTopRansomwareAttacked([])
        }else if(sortedDevices[0].totalAttacks === 0){
            setTopRansomwareAttacked([])
        }else if(sortedDevices.length === 1){
            setTopRansomwareAttacked(sortedDevices.slice(0, 1))
        }else if(sortedDevices[1].totalAttacks === 0){
            setTopRansomwareAttacked(sortedDevices.slice(0, 1))
        }else if(sortedDevices.length === 2){
            setTopRansomwareAttacked(sortedDevices.slice(0, 2))
        }else if(sortedDevices[2].totalAttacks === 0){
            setTopRansomwareAttacked(sortedDevices.slice(0, 2))
        }else if(sortedDevices.length === 3){
            setTopRansomwareAttacked(sortedDevices.slice(0, 3))
        }else if(sortedDevices[3].totalAttacks === 0){
            setTopRansomwareAttacked(sortedDevices.slice(0, 3))
        }else{
            setTopRansomwareAttacked(sortedDevices.slice(0, 4))
        }
    }


    function onApiFailure(e: Error) {
        setLoading(false)
        throw e
    }

    async function loadAlertsPerDay(accountId: string, role: string) {
        loadAlertsPerDayBySeverity(accountId, role, 'ids').then(onLoadAlertsPerDay).then(setIDSAlertsPerDay)
        loadAlertsPerDayBySeverity(accountId, role, 'ransomware').then(onLoadAlertsPerDay).then(setRansomwareAlertsPerDay)
    }

    // severity must be only one option in string format
    async function loadAlertsPerDayBySeverity(accountId: string, role: string, severity: Severity) {
        const todate = moment()
        const fromdate = moment().subtract(14, 'days')

        let url = ''
        if (role === 'admin')
            url = `/api/v2/accounts/${accountId}/alerts-per-day`
        else
            url = `/api/v2/alerts-per-day`
        url = url.concat(`?date_from=${fromdate.format('YYYY-MM-DD')}&date_to=${todate.format('YYYY-MM-DD')}&severities=${translateSeverity(severity)}&categories=detection&timezone=${getTimeZone()}&include_subaccounts=true`)

        return makeAuthenticatedRequest({ url }).then((response) => {
            return {response: response as AlertsPerDayResponse, fromdate, todate}
        })
    }

    function onLoadAlertsPerDay({response, fromdate, todate}: {response: AlertsPerDayResponse, todate: moment.Moment, fromdate: moment.Moment}){
        const numberOfDays = todate.diff(fromdate, 'days')

        // Build labels for every day since starting date
        const labels: string[] = []
        const nextdate = fromdate
        for (let day = 0; day < numberOfDays; day++)
            labels.push(nextdate.add(1, 'days').format('MM/DD/YYYY'))

        // Tranfsorm response into a map
        const countByDay = new Map<string, number>()
        response.alerts_per_day.forEach((elem) => {
            const reformat = moment(elem.day).format('MM/DD/YYYY')
            countByDay.set(reformat, elem.count)
        })

        // Fill values based on map
        const values: number[] = []
        labels.forEach((day) => {
            const count = countByDay.get(day)
            if (count)
                values.push(count)
            else
                values.push(0)
        })

        return { labels, values }
    }

    async function loadAlertsPerMonth(accountId: string, role: string) {
        const todate = moment().format('YYYY-MM-DD');
        const fromdate = moment().subtract(1, 'years').format('YYYY-MM-DD')

        let url = ''
        if (role === 'admin')
            url = `/api/v2/aggregations/${accountId}/alerts-per-month?date_from=${fromdate}&date_to=${todate}&severity_type=all`
        else
            url = `/api/v2/aggregations/alerts-per-month?date_from=${fromdate}&date_to=${todate}&severity_type=all`

        return (makeAuthenticatedRequest({ url }))
            .then(response => {
                return onLoadAlertsPerMonth(response as AlertsPerMonthResponse)
            })
            .catch(onApiFailure)
    }

    function onLoadAlertsPerMonth(response: AlertsPerMonthResponse){
        const months = pastYearMonthsArray();
        response.alerts_per_month.forEach((item) => {

            if (months[moment(item.month).format('YYYYMM')]) {
                months[moment(item.month).format('YYYYMM')].value = item.count;
            }
        })

        const chartData: any = {
            labels: [],
            values: []
        }

        Object.keys(months).forEach((key: any) => {
            chartData.labels.push(months[key].label)
            chartData.values.push(months[key].value)
        })

        setAlertsPerMonth(chartData)
    }

    async function loadAlertsCSV() {
        return makeAuthenticatedDownloadRequest({
            url: `/api/v2/alerts`,
            filename: "alerts_report.csv"
        })
        .catch(onApiFailure);
    }

    async function loadDeviceAlertsCSV(deviceId: string, hostName: string) {
        return makeAuthenticatedDownloadRequest({
            url: `/api/v2/devices/${deviceId}/alerts?sort=-timestamp`,
            options: {method: 'GET'},
            filename: hostName + "_alerts_report.csv"
        })
        .catch(onApiFailure)
    }

    // Set filters and sorting
    async function setLoadOptions(options: Partial<LoadingOptions>) {
        setLoadingOptions((previousOptions) => fillPartialOptions(previousOptions, options))
    }

    // Set filters and sorting for selected endpoint
    async function setSelectedEndpointLoadOptions(options: Partial<LoadingOptions>) {
        setSelectedEndpointLoadingOptions((previousOptions) => fillPartialOptions(previousOptions, options))
    }
    
    function fillPartialOptions(previous: LoadingOptions, current: Partial<LoadingOptions>) {
        return {
            ...previous,
            ...(current?.severitiesFilter && { severitiesFilter: current.severitiesFilter }),
            ...(current?.categoriesFilter && { categoriesFilter: current.categoriesFilter }),
            ...(current?.mitigationFilter && { mitigationFilter: current.mitigationFilter }),
            ...(current?.sortBy && { sortBy: current.sortBy }),
            ...(current?.order && { order: current.order }),
            ...(current?.count && { count: current.count }),
            ...(current.hasOwnProperty('cursor') && { cursor: current.cursor })
        }
    }

    async function getAlertsCount(severity: Severity, mitigated?: boolean, accountId?: string) {
        // Set base url
        let url = `/api/v2/alerts`
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/alerts`

        // Add query params
        url = url.concat(`?severities=${translateSeverity(severity)}&categories=detection&count=10&sort=-timestamp&include_subaccounts=true`)
        if (mitigated !== undefined)
            if (mitigated)
                url = url.concat(`&mitigated=True`)
            else    
                url = url.concat(`&mitigated=False`)

        return makeAuthenticatedRequest({ url })
            .then((response: AlertsResponse) => response.total)
    }

    return (
        <AlertsContext.Provider value={{
            loading,
            alerts,
            total,
            topAttacked: topIDSAttacked,
            topRansomwareAttacked,
            alertsPerMonth,
            idsAlertsPerDay,
            ransomwareAlertsPerDay,
            cursorBefore,
            cursorAfter,
            cursorLast,
            loadingOptions,
            selectedEndpointAlerts,
            selectedEndpointTotal,
            selectedEndpointCursorBefore,
            selectedEndpointCursorAfter,
            selectedEndpointCursorLast,
            selectedEndpointLoadingOptions,
            loadAlerts,
            loadSelectedEndpointAlerts,
            loadTopIDSAttacked,
            loadTopRansomwareAttacked,
            loadAlertsPerMonth,
            loadAlertsPerDay,
            downloadAlertsCSV: loadAlertsCSV,
            downloadDeviceAlertsCSV: loadDeviceAlertsCSV,
            setLoadOptions,
            setSelectedEndpointLoadOptions,
            getAlertsCount
        }}>
            { children }
        </AlertsContext.Provider>
    )
}

interface TopAttackedResponse {
    total: number
    items: TopAttackedItem[]
}

type TopAttackedItem = {
    device: {
        id: string,
        ip_address: string,
        user_id: string,
        policy_id: string,
        type: string,
        hostname: string,
        windows_device_configuration_id: string,
        entropy_score: number,
        last_heartbeat: string,
        total_alerts: number,
        agent_version: string
    },
    total_attacks: number
}

export type AttackedDevice = {
    id: string,
    hostname: string,
    totalAttacks: number
}

interface AlertsPerMonthResponse {
    alerts_per_month: {
        month: string,
        count: number
    }[]
}

interface AlertsPerDayResponse {
    alerts_per_day: {
        day: string,
        count: number
    }[]
}

type AlertsResponse = {
    items: Alert[],
    total: number,
    metadata: Metadata
}




function getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}
